<template>
  <div class="nav-bar-header">
    <div class="nav-bar-center">
      企业总数：
      <span style="color: #007ef2">{{ totalCompany || "0" }} 家</span> &nbsp;
      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;| &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
      <!-- 今日访问量：
      <span style="color: #007ef2">{{ todayTotal || "0" }} 人</span> &nbsp;
      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;|&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp; -->
      累计访问量：
      <span style="color: #007ef2">{{ displayTotalView || "0" }} 人</span>
    </div>
  </div>
</template>
<script>
import { theme1, urlBrowseEntStatTheme1,infoV2 } from "@/api/engineering";
export default {
  data() {
    return {
      totalCompany: "",
      todayTotal: "",
      totalView: "",
    };
  },
  computed: {
    displayTotalView() {
      return Number(this.totalView || 0) //+ Number(this.totalCompany || 0) 
    }
  },
  mounted() {
    this.getTheme1();
    // this.getTheme2();
    this.getInfoV2()
  },
  methods: {

    async getInfoV2() {
      try {
        const { data } = await infoV2({});
        let totlaCompany = data.filter(item=>item.name == '企业总量')
        let totlaVisitData = data.filter(item=>item.name == '访问数据')
        if (totlaCompany && totlaCompany.length) {
          this.totalCompany = totlaCompany[0].count
        }

        console.log('XXXXXV2',data)
        // this.todayTotal = data.todayTotal;
        // this.totalView = data.total;
      } catch (error) {
        console.log("err", error);
      }
    },


    async getTheme1() {
      try {
        const { data } = await theme1({});
        this.todayTotal = data.todayTotal;
        this.totalView = data.total;
      } catch (error) {
        console.log("err", error);
      }
    },
    // async getTheme2() {
    //   try {
    //     const { data } = await urlBrowseEntStatTheme1({});
    //     this.totalCompany = data.total;
    //   } catch (error) {
    //     console.log("err", error);
    //   }
    // },
  },
};
</script>
<style>
.nav-bar-header {
  width: 1520px;
  height: 50px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  align-items: center;
  margin: 0 auto 20px;
  justify-content: center;
}
.nav-bar-center {
  text-align: left;
  width: 100%;
}
</style>
