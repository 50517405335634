import { getRegion } from "@/api/account";
import { modifyTree, provinceArray } from '@/utils/public'

export default {
  namespaced: true,
  state: {
    addressData: [], // 原省市区地址
    addressDataNew: [], // 修改属性后的省市区地址
    twoAddress: [], // 地址的二维数组，省市
    publicAddress: '' // 全局选中后的城市
  },
  mutations: {
    ADDRESSDATA: (state, data) => {
      const treeConfig = {
        data: data,
        children: ['children', 'child'],
        keys: [['name', 'label'], ['adCode', 'value'], ['child', 'children']]
      }
      state.addressData = data
      state.addressDataNew = modifyTree(treeConfig)

      let arr = JSON.parse(JSON.stringify(state.addressDataNew))
      arr.map(item => {
        item.children.map(items => {
          delete items.children
        })
      })
      state.twoAddress = arr
    },
    PUBLICADDRESS: (state, data) => {
      state.publicAddress = data
    }
  },
  actions: {
    storeAddress({ rootState, commit }, data) {
      return new Promise((resolve, reject) => {
        getRegion().then(res => {
          commit('ADDRESSDATA', res.data);
          resolve();
        })
      })
    },
  },
};
