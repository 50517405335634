import layout from '@src/subpageLayout'
let pre = 'ecosystem-'
export default {
  path: '/ecosystem',
  name: 'ecosystem',
  redirect: {
    name: `${pre}ecosystem`,
  },
  component: layout,
  children: [
    {
      path: 'ecosystem',
      name: `${pre}ecosystem`,
      meta: {
        title: '首页',
        header: true,
        auth: false,
      },
      component: () => import('@view/ecosystem'),
    }
  ],
}
