import layout from '@src/fristPageLayout'
let pre = 'dataCenter-'
export default {
  path: '/dataCenter',
  name: 'dataCenter',
  redirect: {
    name: `${pre}dataCenter`,
  },
  component: layout,
  children: [
    {
      path: 'dataCenter',
      name: `${pre}dataCenter`,
      meta: {
        title: '首页',
        header: true,
        auth: false,
      },
      component: () => import('@view/dataCenter'),
    }
  ],
}
