import layout from "@src/layout"
let pre = "certificate-"
export default {
    path: '/certificate',
    name: 'certificate',
    redirect: {
        name: `${pre}certificate`
    },
    component: layout,
    children: [{
        path: 'index',
        name: `${pre}index`,
        meta: {
            title: "电子证照",
            header: true,
            auth: true,
        },
        component: () =>
            import ("@view/certificate")
    }, {
        path: 'details',
        name: `${pre}details`,
        meta: {
            title: '电子证照详情',
            header: true,
            auth: true,
        },
        component: () =>
            import ("@view/certificate/certificateDetails")
    }]
}
