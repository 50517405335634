import layout from '@src/fristPageLayout'
let pre = 'moreNew-'
export default {
  path: '/moreNew',
  name: 'moreNew',
  redirect: {
    name: `${pre}moreNew`,
  },
  component: layout,
  children: [
    {
      path: 'moreNew',
      name: `${pre}moreNew`,
      meta: {
        title: '首页',
        header: true,
        auth: false,
      },
      component: () => import('@view/moreNew'),
    }
  ],
}
