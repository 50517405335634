import layout from "@src/layout"
let pre = "record-"
export default {
    path: '/record',
    name: 'record',
    redirect: {
        name: `${pre}record`
    },
    component: layout,
    children: [{
        path: 'index',
        name: `${pre}index`,
        meta: {
            title: "备案查询",
            header: true,
            auth: true,
        },
        component: () =>
            import ("@view/record")
    }, {
        path: 'detail',
        name: `${pre}/detail`,
        meta: {
            title: '企业详情',
            header: true,
            auth: true
        },
        component: () => import('@view/record/detail'),
    }]
}