import request from "@src/plugins/request"

// 会员支付前调此订单
export const beforeCreate = function (data) {
  return request({
    url: `/member-order/beforeCreate`,
    method: 'POST',
    data
  })
};


// 获取会员列表数据
export const memberInfoList = function (data) {
  return request({
    url: `/member-user-info/v2/buy-member-list`,
    method: 'GET',
  })
};

// 是否是会员
export const isMember = function (data) {
  return request({
    url: `/member-user-info/is-member`,
    method: 'GET',
    params:data
  })
};


// 生成支付订单
export const createMemberOrder = function (data) {
  return request({
    url: `/member-order`,
    method: 'POST',
    data
  })
};

// 创建二维码
export const createORCode = function (data) {
  return request({
    url: `/QrcodeImg/newQrCode`,
    method: "POST",
    data,
    responseType: 'blob', // 声明返回blob格式
  });
};

// 查看订单详情
export const getOrderDetail = function (data) {
  return request({
      url: `/pay/order/query`,
      method: "GET",
      params:data
  });
};

// 查看订单详情
export const getMemberOrderDetail = function (data) {
  return request({
      url: `/api/member-order/detail`,
      method: "GET",
      params:data
  });
};