<template>
  <div>
    <el-dialog :visible="true" :close-on-click-modal="false" :show-close="false" :width=" screenZoom < 1 ? '920px' : '720px'">
      <div>
        <div class="close-icon" @click="handleClose">
          <i name="el-icon-close"></i>
        </div>
        <div class="login-form">
          <div style="position:absolute;right:0;top:0;" v-if="loginType === 'login'" @click="changLogin">
            <el-image
              :src="laginMethods ? require('@/assets/icon_login/qrcode.png') : require('@/assets/icon_login/pc.png')"
              style="width:80px;height:80px;" />
          </div>
          <div class="left-content">
            <div class="logo">
              <el-image :src="require('@/assets/icon_login/login-logo.png')"></el-image>
            </div>
            <div class="slogan" style="margin-top:12px">邦建通-数字建筑平台</div>
            <div class="slogan">建筑业数字化解决方案</div>
            <div>
              <el-image :src="require('@/assets/icon_login/login-icon.png')" style="width:280px;height:280px;"></el-image>
            </div>
          </div>
          <div class="right-content">
            <template v-if="laginMethods">
              <div class="title"> {{ loginType === 'login' ? '登录' : loginType === 'createUser' ? '注册' : '忘记密码' }} </div>
              <div style="display:flex;align-items:flex-start" v-if="loginType === 'login'">
                <div @click="changLoginAccount(0)"
                  style="margin-right:34px;display:flex;flex-direction:column;align-items:center">
                  <el-button type="text" class="login-type-button"
                    :style="{ color: isAcctount == 0 ? '#007EF2' : '#212121' }">账号登录</el-button>
                  <div class="login-type-line" v-show="isAcctount == 0"></div>
                </div>
                <div v-if="formData.type != 3" @click="changLoginAccount(1)"
                  style="display:flex;flex-direction:column;align-items:center">
                  <el-button type="text" class="login-type-button"
                    :style="{ color: isAcctount == 1 ? '#007EF2' : '#212121' }">快捷登录</el-button>
                  <div class="login-type-line" v-show="isAcctount == 1"></div>
                </div>
              </div>
              <el-form @keyup.enter.native="handleLogin('formData')" :model="formData" :rules="rules" label-width="0px"
                ref="formData">
                <template v-if="isAcctount == 0 && loginType === 'login'">
                  <div class="margintop20 color8">账号</div>
                  <el-form-item prop="username" class="margintop10">
                    <el-input type="text" :maxlength="30" style="width:100%" placeholder="请输入账号" @blur="handleBlur"
                      v-model="formData.username"></el-input>
                  </el-form-item>
                  <div class="margintop20 color8">密码</div>
                  <el-form-item label="" prop="password" class="margintop10">
                    <el-input :maxlength="20" style="width:100%" placeholder="请输入账号密码" type="password" show-password
                      v-model="formData.password"></el-input>
                  </el-form-item>
                  <div class="margintop20 color8" style="margin-bottom:10px">验证码</div>
                  <el-form-item prop="captcha">
                    <div class="a-input-code">
                      <el-input type="text" placeholder="请输入验证码" :maxlength="6" style="flex-grow: 0; margin-right: 10px"
                        v-model="formData.captcha"></el-input>
                      <div class="code-box" v-loading="loadingCode">
                        <el-image @click.native="handleResetCode" v-if="codeImg" style="width: 100%; height: 100%"
                          :src="codeImg" fit="fill">
                          <span slot="error">
                            <el-button size="small" type="text">点击重新加载</el-button>
                          </span>
                        </el-image>
                        <el-button size="small" v-else type="text">验证码区</el-button>
                      </div>
                    </div>
                  </el-form-item>
                </template>
                <template v-if="isAcctount == 1 || loginType !== 'login'">
                  <div class=" color8" :class="{ 'margintop20': loginType === 'login' }">手机号
                  </div>
                  <el-form-item prop="userPhone" class="margintop10">
                    <el-input :maxlength="20" placeholder="请输入手机号" @blur="handleBlur" style="width:320px"
                      v-model="formData.userPhone"></el-input>
                  </el-form-item>

                  <template v-if="loginType !== 'login'">
                    <div class="margintop20 color8">密码</div>
                    <el-form-item prop="newPassword" class="margintop10">
                      <el-input :maxlength="16" placeholder="请输入密码" style="width:320px" v-model="formData.newPassword"
                        type="password" show-password></el-input>
                    </el-form-item>

                    <div class="margintop20 color8">确认密码
                    </div>
                    <el-form-item prop="confirmPassword" class="margintop10">
                      <el-input :maxlength="16" placeholder="再次确认密码" style="width:320px"
                        v-model="formData.confirmPassword" type="password" show-password></el-input>
                    </el-form-item>
                  </template>

                  <div class="margintop20 color8">验证码</div>
                  <el-form-item prop="captchaPhone" class="margintop10">
                    <div class="a-input-code">
                      <el-input type="text" placeholder="请输入验证码" :maxlength="6" v-model="formData.captchaPhone">
                        <el-button type="text" @click="toSendCode" :disabled="!canSendCode || sendCodeLoading"
                          class="code-box-phone" slot="suffix">
                          {{ countDown === 60 ? "发送验证码" : countDown + 's重新发送' }}
                        </el-button>
                        <!-- sendSms -->
                      </el-input>
                    </div>
                  </el-form-item>
                </template>

              </el-form>
              <div class="margintop20">
                <el-button class="login-btn" type="primary" :loading="loadingStatus" @click="handleLogin('formData')">
                  {{ loginType === 'login' ? "登录" : loginType === 'createUser' ? '注册' : "确认" }}
                </el-button>
              </div>
              <div class="margintop20" style="display:flex;">
                <el-button type="text" @click="changeFormType" class="register-btn" style="margin-right: 20px">
                  {{ loginType !== 'login' ? '返回登录' : '没有账号？立即注册' }}
                </el-button>
                <el-button type="text" @click="handleForgotPwd()" v-if="isAcctount == 0 && loginType === 'login'"
                  class="forget-btn">忘记密码？</el-button>
                <el-button type="text" @click="toCreateNewUser" v-if="isAcctount == 0 && loginType === 'forgetPwd'"
                  class="forget-btn">去注册</el-button>
              </div>
            </template>
            <template v-else>
              <iframe id="wxLoginFrameID" frameborder="no" border="0" marginwidth="0" marginheight="0"
                sandbox="allow-scripts allow-top-navigation allow-same-origin" scrolling="no" title="Inline Frame Example"
                width="100%" :height="screenZoom < 1 ? '620px' : '420px'" :src="codeurl">
              </iframe>
            </template>
            <div style="margin-top:20px;font-size:14px" class="color8" v-if="loginType !== 'forgetPwd'">
              <span> 登录即表示已阅读并同意</span>
              <a href="https://api.yanxin7.cn/api/user/SysTerms/get?type=yonghuxieyi" target="_blank"
                style="color:#007EF2;text-decoration:none">《用户协议》</a>
              <span>和</span>
              <a href="https://api.yanxin7.cn/api/user/SysTerms/get?type=yinsizhengce" target="_blank"
                style="color:#007EF2;text-decoration:none">《隐私政策》</a>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script >
import wxlogin from "vue-wxlogin";
import {
  accountAuthLoginPic,
  wechatLogin,
  getLoginSms,
  createUser
} from "@src/api/account.js";
import { resetPassword } from "@/api/personalCenter"
import { NEWsendVerifyCode } from "@/api/common"
import { isMobile } from "@/utils/validate";
import drag1 from "@/assets/login_company/login_icon_left.png";
import login_bg from "@/assets/login_company/login_bg.png";
import { mapActions } from "vuex";
import Utils from "@/utils/shift"
export default {
  props: {
    routeRedirect: {
      type: String,
      default: "/",
    },
  },
  components: { wxlogin },
  data() {
    var validateName = function (rule, value, callback) {
      if (value.length >= 2 && value.length <= 20) {
        callback();
      } else {
        callback(new Error("用户名为2~20位"));
      }
    };

    let isMobileVail = function (rule, value, callback) {
      if (!isMobile(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }

    }

    var validatePass = function (rule, value, callback) {
      var reg = /\S{6,15}$/;
      if (!reg.test(value)) {
        callback(new Error("密码长度6~15,密码规则不正确"));
      } else {
        callback();
      }
    };
    return {
      loginType: "login",  //createUser注册用户,forgetPwd忘记密码

      userInfo: {},
      titleName: "施工企业资质业绩查询系统",
      codeurl: "",
      title: "请微信扫描二维码注册",
      visible: false,
      loginBg: login_bg,
      imgSrc: drag1,
      sentSmsStr: "获取验证码",
      smsWait: 0,
      sendClass: false,
      isAcctount: 0,
      laginMethods: true,
      loadingStatus: false,
      loadingCode: false,
      codeImg: "",
      inputType: "password",
      inComeH5: false,
      formData: {
        bySms: 0,
        username: "",
        password: "",
        captcha: "",
        type: 0,
        userPhone: "",
        captchaPhone: "",
        newPassword: "",
        confirmPassword: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入账号", trigger: "blur" },
          { validator: validateName, trigger: "blur" },
        ],
        captcha: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        captchaPhone: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        userPhone: [{ required: true, message: "请输入手机号", trigger: "blur" }, { validator: isMobileVail, trigger: ["blur"] }],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { validator: this.validatePassword, trigger: "blur" },
        ],
        confirmPassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { validator: this.validatePassword, trigger: "blur" },
          { validator: this.valiSamePwd, trigger: ["blur", "change"] }
        ],

      },
      curentUrl: "",
      isJR_Company: false,


      isSendCode: false,//是否发送过验证码
      canSendCode: true,//是否可以发送验证码
      timer: null,
      countDown: 60,

      sendCodeLoading: false,
      screenZoom: 1,

    };
  },
  created() {
    this.wechatLogin();

    this.$nextTick((res) => { });
  },
  mounted() {
    var query = this.$route.query;
    if (this.$route.query.cityCodes) {
      sessionStorage.setItem("cityCodes", this.$route.query.cityCodes);
    }
    if (query.id) {
      this.inComeH5 = true;
    }
    this.handleBlur();
    const screenWidth = window.screen.width;
    if (screenWidth < 1520) {
      this.screenZoom = (screenWidth / 1520)
    }
    
  },
  methods: {
    // 扫码登录
    wechatLogin() {
      let url = window.location.href;
      if (this.$route.path === "/dataCenter/dataCenter") {
        let findIndex = url.indexOf("?code=")
        if (findIndex !== -1) {
          url = url.substring(0, findIndex)
        }
      }
      let that = this;
      this.$axios.get("https://www.bangjiantong.com/bxbh-api/sys/qrconnect", {
        params: { url, },
      }).then(function (response) {
        that.codeurl = response.data.data;
      }).catch(function (error) {
        console.log("扫码登录错误", error);
      });
    },

    // 发送验证码
    async toSendCode() {
      try {
        let phone = this.formData.userPhone
        if (!phone) {
          this.$message.warning("请输入手机号")
          return
        }
        if (!isMobile(phone)) {
          this.$message.warning("手机号格式不正确")
          return
        }
        if (!this.canSendCode) return
        if (this.timer) {
          this.countDown = 60
          clearInterval(this.timer)
        }
        this.sendCodeLoading = true

        if (this.loginType === "forgetPwd") {
          await NEWsendVerifyCode({
            phone,
            smsEnum: "PHONE_COMMON",
          })
        } else {
          await getLoginSms({ phone })
        }
        this.$message.success("发送短信成功");
        this.timer = setInterval(() => {
          if (!this.isSendCode) {
            this.isSendCode = true
          }
          this.countDown--
          if (this.countDown <= 0) {
            this.countDown = 60
            this.canSendCode = true
            clearInterval(this.timer)
          } else {
            this.canSendCode = false
          }
        }, 1000);
        this.sendCodeLoading = false

      } catch (err) {
        this.sendCodeLoading = false

        console.log("err", err)
        // this.$message.error(err?.msg)
      }
    },

    changLoginAccount(type) {
      this.$refs.formData.clearValidate()
      this.isAcctount = type;
    },
    changLogin() {
      if (this.$refs["formData"]) {
        this.$refs.formData.clearValidate()
      }
      this.laginMethods = !this.laginMethods;
    },
    handleClose() {
      this.$emit("close");
    },
    ...mapActions("account", ["accountLogin", "getNewUserInfo"]),
    // 输入框失去焦点
    handleBlur() {
      if (this.formData.username && this.formData.username.length >= 2) {
        if (this.loadingCode) {
          return;
        }
        this.loadingCode = true;
        accountAuthLoginPic({ username: this.formData.username })
          .then((res) => {
            this.loadingCode = false;
            this.codeImg = res.data;
          })
          .catch((err) => {
            this.codeImg = "error";
            this.loadingCode = false;
          });
      }
    },

    // 刷新验证码
    handleResetCode() {
      this.handleBlur();
    },

    // 登录
    handleLogin(name) {
      var that = this;

      if (this.loginType === 'forgetPwd') {
        this.toResetPassword()
        return
      }

      if (this.loginType === "createUser") {
        this.toCreateUser()
        return
      }

      this.formData.bySms = this.isAcctount;

      let data = {};
      if (this.formData.bySms == 0) {
        data.username = this.formData.username;
        data.password = this.formData.password;
        data.captcha = this.formData.captcha;
        data.type = this.formData.type;
        data.bySms = this.formData.bySms;
      } else {
        data.username = this.formData.userPhone;
        data.captcha = this.formData.captchaPhone;
        data.type = this.formData.type;
        data.bySms = this.formData.bySms;
      }

      that.$refs[name].validate((vail) => {
        if (vail) {
          if (this.isAcctount == 1 && !this.isSendCode) {
            this.$message.warning("请先获取验证码")
            return
          }
          that.loadingStatus = true;
          that.accountLogin(data).then((res) => {
            that.loginSuccess(res.data)
            that.loadingStatus = false;
          }).catch((err) => {
            that.loadingStatus = false;
            // this.$message.error(err?.msg || "登录失败")
          });
        }
      });
    },

    // 重置密码
    toResetPassword() {
      this.$refs.formData.validate(async vali => {
        if (!vali) return

        if (!this.isSendCode) {
          this.$message.warning("请先获取验证码")
          return
        }

        try {
          this.loadingStatus = true
          await resetPassword({
            phone: this.formData.userPhone,
            type: 1,
            code: this.formData.captchaPhone,
            newPassword: this.formData.newPassword
          })
          this.loadingStatus = false
          this.$message.success("密码重置成功")
          this.formData.captchaPhone = ""
          this.formData.newPassword = ""
          this.loginType = 'login'
        } catch (err) {
          // this.$message.error(err?.msg || "密码重置失败")
          this.loadingStatus = false
        }
      })
    },

    // 注册用户
    toCreateUser() {
      this.$refs.formData.validate(async vali => {
        if (!vali) return
        try {
          if (!this.isSendCode) {
            this.$message.warning("请先获取验证码")
            return
          }
          this.loadingStatus = true
          let params = {
            phone: this.formData.userPhone,
            code: this.formData.captchaPhone,
            password: this.formData.newPassword,
          }
          let { data } = await createUser(params)
          this.loadingStatus = false
          this.$store.commit("account/setToken", data.token);
          this.$store.dispatch("account/getNewUserInfo")
          this.loginSuccess(data)
        } catch (err) {
          // this.$message.error(err?.msg || "用户注册失败")
          this.loadingStatus = false
        }
      })
    },

    loginSuccess(data) {
      let that = this
      if (data?.creditCode) {
        sessionStorage.setItem("creditCode", data.creditCode);
      }
      if (that.routeRedirect) {
        var toPage = that.routeRedirect || "/";
        that.$emit("loginSuccess", toPage);
      } else if (that.inComeH5) {
        that.$emit("loginSuccess", "/bidder/allProjects");
      } else {
        that.$emit("loginSuccess", "/");
      }
      Utils.$emit("loginSuccess")
    },


    clearTimer() {
      this.isSendCode = false
      this.canSendCode = true
      clearInterval(this.timer)
      this.countDown = 60
      this.formData.captchaPhone = ""
    },

    changeFormType() {
      this.clearTimer()
      if (this.loginType === "login") {
        this.loginType = "createUser"
      } else {
        this.loginType = "login"
      }
      this.$refs.formData.clearValidate()
    },

    toCreateNewUser() {
      this.clearTimer()
      this.loginType = "createUser"
      this.$refs.formData.clearValidate()
    },

    handleForgotPwd() {
      this.clearTimer()
      this.loginType = "forgetPwd"
      this.$refs.formData.clearValidate()
    },


    validatePassword(rule, value, callback) {
      if (!/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^\da-zA-Z\s]).{8,16}$/.test(value)) {
        callback(new Error("密码仅支持8-16位的英文字母、数字、字符组合"))
      }
      callback();
    },
    valiSamePwd(rule, value, callback) {
      if (this.formData.newPassword !== value) {
        callback(new Error("两次密码不一致"))
      }
      callback();
    },
  },
};
</script>

<style scoped>
.color8 {
  color: #888;
}

.margintop20 {
  margin-top: 20px;
}

.margintop10 {
  margin-top: 10px;
}

.login-form {
  background: #fff;
  border-radius: 20px;
  display: flex;
  align-items: flex-start;
  padding: 40px 26px 32px 40px;
  font-size: 16px;
  position: relative;
  overflow: hidden;
}

.el-form-item {
  margin-bottom: 0;
}

.close-icon {
  position: absolute;
  width: 20px;
  height: 20px;
  cursor: pointer;
  right: -20px;
  top: -10px;
}

.close-icon:before,
.close-icon:after {
  position: absolute;
  content: "";
  height: 20px;
  width: 2px;
  background-color: #C5C5C5;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}


.close-icon:before {
  transform: rotate(45deg);
}

.close-icon:after {
  transform: rotate(-45deg);
}

.close-icon:hover:before,
.close-icon:hover:after {
  background-color: white;
}


.left-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}

.logo {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background: #007EF2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo>image {
  width: 36px;
  height: 36px;
}

.slogan {
  font-size: 18px;
  font-weight: bold;
  color: #007EF2;
  padding-bottom: 10px;
}

.right-content {
  margin-left: 35px;
  text-align: left;
  flex-grow: 1;
}

.right-content>.title {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 20px;
  text-align: center;
  color: #212121;
}

.login-type-button {
  font-size: 18px;
  padding: 0;
}

.login-type-line {
  width: 70px;
  height: 2px;
  background: #007EF2;
  margin-top: 3px;
}

.login-btn {
  background: #007EF2;
  width: 320px;
  padding: 12px 0;
  border-radius: 4px;
}

.register-btn {
  color: #007EF2;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.forget-btn {
  color: #888;
  font-size: 16px;
  margin: 0;
  padding: 0;
}










.div-title {
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.div-title-item {
  font-size: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.div-title-item-buttom {
  margin-top: 10px;
  height: 3px;
  width: 60px;
  border-radius: 20px;
}

.is_active {
  background-color: white;
}

.code-logon {
  font-size: 15px;
  display: flex;
  flex-direction: row-reverse;
  color: white;
  margin-right: 10px;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.img-code {
  margin-left: 10px;
  width: auto;
  height: 4.5vh;
}

.a-login-box {
  background: url(~@/assets/login_company/login_bg.png) 0 0 no-repeat;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: relative;
}

.big-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.a-login-body-bg {
  height: 470px;
  width: 24%;
  background: rgba(255, 255, 255, 0.3);
  position: relative;
  background-image: url(~@/assets/login_company/login_left_s.png),
    url(~@/assets/login_company/login_right_s.png),
    url(~@/assets/login_company/login_left_x.png),
    url(~@/assets/login_company/login_right_x.png);
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-position: top left, top right, bottom left, bottom right;
  background-size: 40px 40px;
  z-index: 1;
  min-width: 400px;
}

.a-login-body {
  height: 100%;
  background: rgba(rgb(204, 31, 31), rgb(179, 57, 57), rgb(95, 74, 74), 1);
  border-radius: 20px;
  position: relative;
  z-index: 1;
}

.a-input-code {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.a-input-code>.code-box {
  width: 45%;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  text-align: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  cursor: pointer;
}

.a-login-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}


.div-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 12.5%;
  height: 100vh;
  padding-top: 12vh;
}

.div-left-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 42px;
  font-weight: 600;
}

.header-center {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.img-left {
  width: auto;
  height: 56%;
}

.img-logo {
  margin-right: 20px;
  width: 50px;
  height: auto;
}

.btn-login {
  width: 100%;
  height: 40px;
  background: linear-gradient(180deg, #01d8f6 0%, #158ffe 100%);
  border-radius: 12px 12px 12px 12px;
  font-size: 16px;
  opacity: 1;
}

.div-register {
  width: 100%;
  color: white;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  cursor: pointer;
}

.code-box-phone {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #007EF2;
  font-size: 16px;
}

.content {
  display: flex;
  align-content: center;
  justify-content: center;
}

.login_container {
  width: 200px;
  height: 200px;
}

.alogin {
  text-decoration: none;
}

.bottom {
  color: white;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  font-size: 19px;
  margin-top: 5vh;
}

.mb-10 {
  margin-bottom: 10px;
}

.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .el-dialog {
  border-radius: 20px;
}

::v-deep .el-dialog__wrapper {
  box-shadow: none;
  border: none;
  background: none;
}

::v-deep .el-dialog__header {
  display: none;
}

::v-deep .el-dialog__body {
  padding: 0;
  background-color: transparent;
}
</style>
