<template>
  <div>
    <el-tabs
      type="border-card"
      class="tabs-card"
      v-model="activeTab"
      @tab-click="handleTabClick"
    >
      <el-tab-pane class="tab-bg" label="施工企业" name="tab1">
        <el-row :gutter="20" type="flex">
          <el-input
            v-model="searchValue"
            placeholder="请输入企业名称"
            @keyup.enter.native="handleSearch"
          ></el-input>
          <el-button type="primary" class="search-btn" @click="handleSearch"
            >搜索</el-button
          >
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="从业人员" name="tab2">
        <el-row :gutter="20" type="flex">
          <el-input
            v-model="searchValue"
            placeholder="请输入从业人员"
            @keyup.enter.native="handleSearch"
          ></el-input>
          <el-button type="primary" class="search-btn" @click="handleSearch"
            >搜索</el-button
          >
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="工程项目" name="tab3">
        <el-row :gutter="20" type="flex">
          <el-input
            v-model="searchValue"
            placeholder="请输入工程项目"
            @keyup.enter.native="handleSearch"
            class="input-box"
          ></el-input>
          <el-button type="primary" class="search-btn" @click="handleSearch"
            >搜索</el-button
          >
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="诚信记录" name="tab4">
        <el-row :gutter="20" type="flex">
          <el-input
            v-model="searchValue"
            placeholder="请输入诚信记录"
            @keyup.enter.native="handleSearch"
          ></el-input>
          <el-button type="primary" class="search-btn" @click="handleSearch"
            >搜索</el-button
          >
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: "tab1",
      searchView: "qualification",
      searchValue: "",
    };
  },
  computed: {},
  components: {},
  methods: {
    handleTabClick(tab) {
      console.log(tab.name);
      switch(tab.name) {
        case 'tab1':
          this.searchView = "qualification";
          break;
          case 'tab2':
          this.searchView = "personnel";
          break;
          case 'tab3':
          this.searchView = "project";
          break;
          case 'tab4':
          this.searchView = "creditscore";
          break;
          default:
            break;
      }
    },
    handleSearch() {
      console.log(this.searchValue);
      // Emit onSearch event here
      this.$emit('onSearch', this.searchView, this.searchValue);
    },
  },
};
</script>
<style scoped>
.tabs-card {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

el-tabs {
  background-color: transparent;
}
::v-deep .el-tabs--border-card > .el-tabs__header {
  background-color: transparent;
}
.search-btn {
  width: 100px;
  height: 42px;
  line-height: 0px;
  background: #007EF2;
  border-radius: 4px 4px 4px 4px;
  position: absolute;
  right: 1.5%;
  font-size: 20px;
  margin-top: 4.5px;
}

::v-deep .el-input > .el-input__inner {
    padding-right: 120px;
}

::v-deep .tabs-card.el-tabs--border-card > .el-tabs__header .el-tabs__item {
  border-radius: 4px 4px 0px 0px;
  margin-right: 4px;
  margin-bottom: 0.6px;
  width: 112px;
  height: 40px;
  font-size: 18px;
  background-color: #0662e6 !important;
  color: white;
}

::v-deep .tabs-card.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  background-color: white !important;
  color: #007EF2 !important;
}

::v-deep .el-tabs__item {
  padding: 0px;
}
::v-deep
  .el-tabs--top.el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:nth-child(2) {
  padding-left: 0px;
}
::v-deep
  .el-tabs--top.el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:last-child {
  padding-left: 15px;
}
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  font-weight: bold;
}
::v-deep .el-tabs--border-card > .el-tabs__header {
  border-bottom: none;
  background-color: none;
}
::v-deep .el-tabs--border-card > .el-tabs__content {
  padding: 0px;
}
::v-deep .el-input__inner {
  border: 1px solid white;
}
::v-deep .el-input__inner {
  width: 900px;
  height: 50px;
  font-size: 20px;
  border-radius: 0px 4px 4px 4px;
  position: relative;
}
</style>
