import layout from "@src/layout"
let pre = "combinedQuery-"
export default {
    path: '/combinedQuery',
    name: 'combinedQuery',
    redirect: {
        name: `${pre}combinedQuery`
    },
    component: layout,
    children: [{
        path: 'combinedForm',
        name: 'combinedForm',
        meta: {
            title: "组合查询",
            header: true,
            auth: true,
            keepAlive:true,
        },
        component: () =>
            import ("@view/combinedQuery/combinedForm")
    },
    {
        path: 'combinedList',
        name: `${pre}index`,
        meta: {
            title: "查询列表",
            header: true,
            auth: true,
        },
        component: () =>
            import ("@view/combinedQuery/combinedList")
    }
]
}