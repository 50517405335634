import request from "@src/plugins/request";

// 获取oss配置
export const getOssSign = function () {
    return request({
        url: "/comment/v2/getOssSign",
        method: "get",
    });
};

// 发短信
export const NEWsendVerifyCode = function (data) {
    return request({
        url: `/common/NEWsendVerifyCode`,
        method: "GET",
        params: data,
    });
};

// 校验验证码
export const checkVerifyCode = function (data) {
    return request({
        url: `/common/checkVerifyCode`,
        method: "POST",
        data,
    });
};

// 个人实名认证接口
export const saveRealPersonInfo = function (data) {
    return request({
        url: "/bxbh/user/save/real/info",
        method: "POST",
        data,
    });
};

// 获取userInfo纯用户信息
export const getUserInfo = function () {
    return request({
        url: "/v2/user/info",
        method: "GET",
    });
};

// 百度身份识别
export const getIdCardByBaidu = function (data) {
    return request({
        url: "/commonOcr/getIdCardByBaidu",
        method: "POST",
        data,
    });
};
