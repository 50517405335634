

export default {
  namespaced: true,
  state: {
    visitTotal: 0, // 总访问量
    registerUserTotal: 0, //用户入驻总量
    todayVisiNum:0   // 今日访问量
  },
  mutations: {
    setVisitTotal(state, num) {
      state.visitTotal = num;
    },
    setRegisterUserTotal(state, num) {
      state.registerUserTotal = num;
    },
    setTodayVisiNum(state, num) {
      state.todayVisiNum = num;
    }
  },
};
