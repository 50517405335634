import request from "@src/plugins/request";

// 用户登录
export const accountAuthLogin = function (data) {
    return request({
        url: "/sys/login",
        method: "POST",
        data,
    });
};

// 通过token获取用户信息(e.g./sys/getUserInfoByToken?token=b952ae95-6916-4b3f-abd3-a72123ae6a69)
export const getUserInfoByToken = function (data) {
    return request({
        url: "/sys/getUserInfoByToken",
        method: "GET",
        params: data,
    });
};

// 用户信息修改
export const accountAuthUpdate = function (data) {
    return request({
        url: "/bxbh/sys/user/password",
        method: "POST",
        data,
    });
};

// 用户信息修改
export const accountUpdate = function (data) {
    return request({
        url: "/bxbh/sys/user/updateInfo",
        method: "POST",
        data,
    });
};

// 查询地区信息
export const getRegion = function (data) {
    return request({
        url: "/region/get",
        method: "GET",
        params: data,
    });
};

//获取图形验证码
export const accountAuthLoginPic = function (data) {
    return request({
        url: "/sys/loginPic",
        method: "get",
        params: data,
    });
};

// 退出登录
export const accountAuthLogout = function (data) {
    return request({
        // url:'/admin/auth/logout',
        url: "/sys/logout",
        method: "GET",
    });
};

//获取图形验证码
export const info = function (data) {
    return request({
        url: "/bxbh/sys/user/info",
        method: "get",
    });
};

//微信扫码登录
export const wechatLogin = function (data) {
    return request({
        url: "/sys/qrconnect",
        method: "get",
        params: data,
    });
};
//扫码登录获取用户信息
export const qrUserInfo = function (data) {
    return request({
        url: "/sys/qrUserInfo",
        method: "get",
        params: data,
    });
};

//我的企业列表
export const getMyCompanyList = function (data) {
    return request({
        url: "/enterprise/staff/getMyCompanyList",
        method: "get",
    });
};
//扫码登录获取用户信息
export const switchCompany = function (data) {
    return request({
        url: "/enterprise/staff/switchCompany",
        method: "get",
        params: data,
    });
};

//获取登录短信验证码
export const getLoginSms = function (data) {
    return request({
        url: "/sys/phoneLogin",
        method: "POST",
        data,
    });
};

//企业列表
export const settledEnterpriseList = function () {
    return request({
        url: `/enterprise/staff/getMyCompanyList`,
        method: "GET",
    });
};

//es四库企业列表
export const getEsMerchantList = function (data) {
    return request({
        url: `/dc/enterprise/es/listPage`,
        method: "POST",
        data,
    });
};

// 电子证照列表
export const urlCertificateList = function (data) {
    return request({
        url: `/en/eclicence/page`,
        method: "POST",
        data,
    });
};

// 电子证照列表搜索
export const urlColumnList = function (data) {
    return request({
        url: `/common/column/list?${data}`,
        method: "GET",
    });
};

// 电子证照详情-参与单位信息
export const urlCertificateWorkInfo = function (data) {
    return request({
        url: `/en/eclicence/comp/list`,
        method: "POST",
        data,
    });
};

// 电子证照详情-单体项目信息
export const urlCertificateProjectInfo = function (data) {
    return request({
        url: `/en/eclicence/unit/page`,
        method: "POST",
        data,
    });
};

// 工程项目列表数据
export const urlProjectList = function (data) {
    return request({
        url: `/enterprise/project/new/page`,
        method: "POST",
        data,
    });
};

// 工程项目招投标信息
export const urlBiddingInfo = function (data) {
    return request({
        url: `/enterprise/project/tender/page`,
        method: "POST",
        data,
    });
};

// 工程项目合同登记信息
export const urlContractInfo = function (data) {
    return request({
        url: `/enterprise/project/contract/page`,
        method: "POST",
        data,
    });
};

// 工程项目施工审查
export const urlReviewInfo = function (data) {
    return request({
        url: `/enterprise/project/censor/page`,
        method: "POST",
        data,
    });
};

// 工程项目施工许可
export const urlPermitInfo = function (data) {
    return request({
        url: `/enterprise/project/builder/page`,
        method: "POST",
        data,
    });
};

// 工程项目验收
export const urlCompletionInfo = function (data) {
    return request({
        url: `/enterprise/project/finish/page`,
        method: "POST",
        data,
    });
};

// 创建用户
export const createUser = function (data) {
    return request({
        url: `/sys/regist`,
        method: "POST",
        data,
    });
};

// 切换企业
export const switchEnterprise = function (data) {
    return request({
        url: `/enterprise/staff/switchCompany`,
        method: "GET",
        params: data,
    });
};
