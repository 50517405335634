import axios from 'axios'
import router from '@src/router'
import store from '@src/store'
import Setting from '@src/setting.js'
import qs from 'qs'

import isPlainObject from 'lodash/isPlainObject'

const http = axios.create({
    baseURL: Setting.apiBaseURL,
    timeout: 1000 * 180,
    withCredentials: true
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
    config.headers = Setting.apiHeaders
    config.headers['Accept-Language'] = 'zh-CN'
    config.headers['X-Litemall-Admin-Token'] = store.state.account.token || ''
        // 默认参数
    var defaults = {}
        // 防止缓存，GET请求默认带_t参数
    if (config.method === 'get') {
        config.params = {
            ...config.params,
            ... { '_t': new Date().getTime() }
        }
    }
    if (isPlainObject(config.params)) {
        config.params = {
            ...defaults,
            ...config.params
        }
    }
    if (isPlainObject(config.data)) {
        config.data = {
            ...defaults,
            ...config.data
        }
        if (/^application\/x-www-form-urlencoded/.test(config.headers['content-type'])) {
            config.data = qs.stringify(config.data)
        }
    }
    return config
}, error => {
    return Promise.reject(error)
})

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
    const fullPath = router.history.current.fullPath;
    var responseData = response.data;
    if (responseData.size) {
        return responseData;
    }
    switch (responseData.code) {
        case 200:
            return response;
        case 501: // 登陆失效
            store.dispatch('account/accountClearCache', '', { root: true })
            router.push({ name: 'login', query: { redirect: fullPath } })
            break;
        case 500: // 登陆失效
            // store.dispatch('account/accountClearCache', '', { root: true })
            // router.push({ name: 'login', query: { redirect: fullPath } })
            break;
        default:
            break;
    }
    return Promise.reject(responseData)
}, error => {
    console.error(error)
    return Promise.reject(error)
})

export default http