const menu = {
  home: [
    {label: '首页', name: 'home'},
    {label: '企业查询', name: 'qualification'},
    {label: '备案查询', name: 'record'},
    {label: '人员查询', name: 'personnel'},
    {label: '工程项目', name: 'project'},
    {label: '电子证照', name: 'certificate'},
    {label: '信用分', name: 'creditscore'}
  ],
  recruit: [
    
    {label: '首页', name: 'recruit'},
    {label: '工程招标', name: 'project-bidding'},
    {label: '政府采购', name: 'government-procurement'},
    {label: '分包集采', name: 'subcontract-acquisition'}
  ],
  engineering: [
    {label: '首页', name: 'engineering'},
    {label: '信息价', name: 'info-price'},
    {label: '参考价', name: 'reference-price'},
    {label: '造价人员', name: 'creative-personnel'},
    {label: '造价公司', name: 'creative-company'}
  ]
}

const meta = (e = {}) => {
  const { activeTab } = e
  return {
    /* configData: 页面内容头部配置
    * menuData: 导航栏
    * title: 标题
    * vice: 副标题
    * search: 搜索框
    * menu: 菜单栏
    * statistics: 统计
    * */
    /* styleData: 页面内容头部样式配置
    * bgStyle: 底层背景图样式
    * bgMaskStyle: 上层背景图样式
    * mainContentStyle: 内容盒子样式
    * titleStyle: 标题样式
    * viceStyle: 副标题样式
    * */
    recruit: {
      configData: {
        title: '投标智能分析管理系统',
        vice: '邦建通-数字建筑平台',
        menuData: [
          { icon: require('@/assets/images/bjtsy/mbz12602.png'), name: '首页', path: '/home/index' },
          { icon: '', name: '人力资源', path: '' },
          { icon: '', name: '融信服务', path: '' },
          { icon: '', name: '保函申请', path: '' }
        ],
        search:  {
          show: true,
          placeholder: '请输入项目名称',
          btn: '立即搜索'
        },
        menu: {
          show: true,
          list: menu.recruit,
          activeTab: activeTab || '',
        },
        statistics: {
          show: true,
          list: [
            { name: "入驻用户量：", value: "-", unit: "人" },
            { name: "今日访问量：", value: "-", unit: "人" },
            { name: "累计访问量：", value: "-", unit: "人" },
          ],
        }
      },
      styleData: {
        bgStyle: `backgroundImage: url(${require(`@/assets/images/bjtsy/bg_1.png`)});`,
        bgMaskStyle: `backgroundImage: url(${require(`@/assets/images/bjtsy/bg_1_mask.png`)});`,
        mainContentStyle: 'padding-bottom: 40px;',
        titleStyle: '',
        viceStyle: ''
      }
    },
    management: {
      configData: {
        title: '建筑行业生态—数字化解决方案服务商',
        searchScheme: {
          show: true
        }
      },
      styleData: {
        bgStyle: `backgroundImage: url(${require(`@/assets/images/bjtsy/bg_3.png`)});`,
        bgMaskStyle: ``,
        mainContentStyle: 'padding-bottom: 114px;',
        titleStyle: 'padding: 0; font-size: 52px;',
        viceStyle: ''
      }
    },
    recruitDetails: {
      configData: {
        title: '建筑行业生态—投标智能分析管理系统',
        vice: '邦建通-数字建筑平台',
      },
      styleData: {
        bgStyle: `backgroundImage: url(${require(`@/assets/images/bjtsy/bg_1.png`)});`,
        bgMaskStyle: `backgroundImage: url(${require(`@/assets/images/bjtsy/bg_1_mask.png`)});`,
        mainContentStyle: 'padding-bottom: 0;',
        titleStyle: '',
        viceStyle: 'margin: 0;'
      }
    },
    engineering: {
      configData: {
        title: '建设工程造价信息服务平台',
        vice: '邦建通-数字建筑平台',
        menuData: [
          { icon: require('@/assets/images/bjtsy/mbz12602.png'), name: '企业查询', path: '/home/index' },
          { icon: '', name: '招标投标', path: '/recruit/index' },
          { icon: '', name: '专属项目', path: '' },
          { icon: '', name: '保函申请', path: '' }
        ],
        search:  {
          show: true,
          placeholder: '关键字查询全国造价信息',
          btn: '立即搜索'
        },
        menu: {
          show: true,
          address: true,
          list: menu.engineering,
          activeTab: activeTab || '',
        },
        statistics: {
          show: true,
          list: [
            { name: "入驻用户量：", value: "-", unit: "人" },
            { name: "今日访问量：", value: "-", unit: "人" },
            { name: "累计访问量：", value: "-", unit: "人" },
          ],
        }
      },
      styleData: {
        bgStyle: `backgroundImage: url(${require(`@/assets/images/bjtsy/bg_4.png`)});`,
        bgMaskStyle: ``,
        mainContentStyle: 'padding-bottom: 40px;',
        titleStyle: 'padding-bottom: 20px; font-size: 30px;',
        viceStyle: 'margin-bottom: 30px; font-size: 24px;'
      }
    },
    engineeringDetails: {
      configData: {
        menu: {
          show: true,
          address: true,
          list: menu.engineering,
          activeTab: activeTab || '',
        }
      },
      styleData: {
        bgStyle: `height: 40px; background-color: #2662E4;`,
        bgMaskStyle: ``,
        mainContentStyle: '',
        titleStyle: '',
        viceStyle: ''
      }
    }
  }
}

export { meta }