<template>
  <el-header class="flex_row_center header" :style="menuStyles.header">
    <div class="flex_row_center header_box">
      <div class="flex_center header_logo">
        <div class="header_logo_icon">
          <el-image :src="menuLogo.img"></el-image>
        </div>
        <b>{{ menuLogo.text }}</b>
      </div>
      <div class="flex">
        <div class="flex_center header_menu">
          <div class="flex_center header_menu_item" v-for="(item, index) in menuData" :key="index" @click="onLink(item)">
            <div class="header_menu_item_icon" v-if="item.icon">
              <el-image :src="item.icon"></el-image>
            </div>
            <span>{{ item.name }}</span>
          </div>
        </div>
        <slot></slot>
      </div>
    </div>
  </el-header>
</template>

<script>
  export default {
    name: "headerNew",
    props: {
      // 导航icon,name,path配置
      menuData: {
        type: Array,
        required: true,
        default: () => []
      },
      // 导航Logo配置
      menuLogo: {
        type: Object,
        default: () => ({
          img: require('@/assets/images/bjtsy/logo.png'),
          text: '邦建通科技集团股份有限公司'
        })
      },
      // 导航样式配置
      menuStyles: {
        type: Object,
        default: () => ({
          header: ''
        })
      }
    },
    methods: {
      onLink(val) {
        if (!val.path) return
        let vRouter = this.$router.resolve({
          path: val.path,
          params: {},
        });
        window.open(vRouter.href, "_blank");
      }
    }
  }
</script>

<style scoped lang="less">
  .header {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    min-width: 1200px;
    height: 40px !important;
    box-shadow: 0 3px 6px 1px rgba(3, 96, 230, 0.06);
    background-color: #fff;
    z-index: 99;
    .header_box {
      width: 1520px;
      justify-content: space-between;
      .header_logo {
        cursor: pointer;
        .header_logo_icon {
          width: 24px;
          min-width: 24px;
          height: 24px;
        }
        > b {
          padding-left: 10px;
          font-size: 18px;
          font-weight: 500;
          color: #2180EF;
          letter-spacing: 2px;
        }
      }
      .header_menu {
        .header_menu_item {
          padding: 0 15px;
          cursor: pointer;
          .header_menu_item_icon {
            width: 18px;
            min-width: 18px;
            height: 18px;
          }
          > span {
            padding-left: 6px;
            color: #4E4E4E;
            font-size: 16px;
          }
        }
        .header_menu_item:last-child {
          padding-right: 0;
        }
      }
    }
  }
</style>
