const throttle = (func, delay) => {
  let timeout = null;
  return function() {
    const context = this;
    const args = arguments;
    if (!timeout) {
      timeout = setTimeout(() => {
        func.apply(context, args);
        timeout = null;
      }, delay);
    }
  };
};
const debounce = (fn, delay) => {
  let timer = null;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function() {
      fn.apply(context, args);
    }, delay);
  }
};
const TimeRange = (range) => {
  const now = Date.now();
  const oneWeekAgo = now - range * 24 * 60 * 60 * 1000;
  const startOfWeek = new Date(oneWeekAgo);
  startOfWeek.setHours(0, 0, 0, 0);
  const startOfWeekTimestamp = startOfWeek.getTime();
  const endOfWeek = new Date(now);
  endOfWeek.setHours(0, 0, 0, 0);
  const endOfWeekTimestamp = endOfWeek.getTime();

  return [startOfWeekTimestamp, endOfWeekTimestamp]
};
const geTime = (date, format) => {
  date = new Date(date);
  let o = {
    'M+': date.getMonth() + 1, //month
    'd+': date.getDate(), //day
    'H+': date.getHours(), //hour
    'm+': date.getMinutes(), //minute
    's+': date.getSeconds(), //second
    'q+': Math.floor((date.getMonth() + 3) / 3), //quarter
    'S': date.getMilliseconds() //millisecond
  };
  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    }
  }
  return format;
};
const modifyTree = (tree) => {
  /*
  * data: 树形结构 -> 数组对象,
  * children: 数组对象的子节点 -> 数组 -> ['children', 'child']
  * keys: 修改的属性名 -> 二位数组 -> [['name', 'label'], ['adCode', 'value'], ['child', 'children']] -> [['旧属性名', '新属性名'], ['旧属性名', '新属性名'], ['旧属性名', '新属性名']]
  * */
  const { data, children, keys } = tree
  let addressList = JSON.parse(JSON.stringify(data));
  function renameProperty(node, oldName, newName) {
    children.forEach(item => {
      if (node[item]) {
        for (let i = 0; i < node[item].length; i++) {
          renameProperty(node[item][i], oldName, newName);
        }
      }
    })
    if (node.hasOwnProperty(oldName)) {
      Object.defineProperty(node, newName, Object.getOwnPropertyDescriptor(node, oldName));
      delete node[oldName];
    }
  }
  for (let i = 0; i < addressList.length; i++) {
    keys.forEach(item => {
      renameProperty(addressList[i], item[0], item[1]);
    })
  }
  return addressList
}
const hideName = (name) => {
  let firstLetter = name.charAt(0);
  let hiddenPart = '*'.repeat(name.length - 1);
  let hiddenName = firstLetter + hiddenPart;
  return hiddenName;
}
const highlightStringInArray = (array, searchString) => {
  let { data, keys } = array;
  data = JSON.parse(JSON.stringify(data));
  for (let i = 0; i < data.length; i++) {
    let item = data[i][keys];
    data[i][keys] = item.replace(new RegExp(searchString, 'gi'), '<span style="color: #EE1E1E;">$&</span>');
  }
  return data;
}

const getAppCustomInfo = () => {
  let currentKey = Object.keys(AppCustomConfig)[0] || 'yanxin7.cn'
  let currentInfo = {
      title: '邦建通',
      companyName: '福建邦标通数智科技有限公司',
      icp: '',
      gongan: '',
      address: '南宁市青秀区中柬路9号利海国际',
  }
  let host = window.location.host
  let findedHost = Object.keys(AppCustomConfig).find(key => host.includes(key))
  // console.log('-----currentKey', currentKey)
  // console.log('-----host', host)
  // console.log('-----findedHost', findedHost)
  if (findedHost) {
      currentKey = findedHost
      currentInfo = AppCustomConfig[currentKey]
  }
  return currentInfo
}


export {
  throttle, //节流
  debounce, // 防抖
  TimeRange, // 时间范围 TimeRange(7)
  geTime, // geTime(new Date(), 'yyyy-MM-dd')
  modifyTree, // 树形结构修改属性值
  hideName, // 名字加密，只显示第一个字
  getAppCustomInfo,
  highlightStringInArray, // 给数组对象某个字符串做高亮显示，用法：highlightStringInArray({data: 数组对象, keys: '数组对象中的属性值'}, '高亮的值')
}