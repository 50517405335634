import axios from "axios";
import router from "@src/router";
import store from "@src/store";
import Setting from "@src/setting.js";
import { Message, Notification, Dialog } from "element-ui";

function errMessageModal(errorMsg) {
    if (Setting.errorModalType == "Message") {
        Message({
            type: "error",
            showClose: true,
            message: errorMsg || "未知错误",
            duration: Setting.errorModalDuration,
        });
    }
    if (Setting.errorModalType == "Notice") {
        Notification({
            type: "error",
            title: "系统错误",
            message: errorMsg || "未知错误",
        });
    }
}

function handleLogout() {
    router.push({
        path: "/dataCenter/dataCenter",
    });

    if (loginType == "inserance") {
        router.push({
            path: "/inseranceLogin?cityCode=" + cityCode,
        });
    }
    if (loginType == "inseranceh5") {
        router.push({
            path: "/inseranceLogin_h5",
        });
    }
    console.log("loginType", loginType);
    let loginTypePC = sessionStorage.getItem("admin-userInfos");
    let loginType2 = JSON.parse(loginTypePC).value.loginType;
    console.log("loginType2", loginType2);
    if (loginType2 == 0) {
        router.push({
            path: "/login",
        });
    }
    if (loginType2 == 1) {
        router.push({
            path: "/JR_login",
        });
    }
    return;
}
const service = axios.create({
    baseURL: Setting.apiBaseURL,
    timeout: 30000,
});
// 请求拦截
service.interceptors.request.use(
    (config) => {
        axios.defaults.baseURL = config.baseURL;
        const token =
            store.state && store.state.account ? store.state.account.token : "";
        config.headers = Setting.apiHeaders;
        config.headers["X-Litemall-Admin-Token"] = token || "";
        return config;
    },
    (error) => {
        // 发送失败
        console.log(error);
        return Promise.reject(error);
    }
);
// 响应拦截
service.interceptors.response.use(
    (response) => {
        // 请求完成
        const fullPath = router.history.current.fullPath;
        var responseData = response.data;
        if (responseData.size) {
            return responseData;
        }
        console.log("responseData", responseData.code);
        switch (responseData.code) {
            case 200:
                return responseData;
            case 301: // 通用错误提示状态码
                errMessageModal(responseData.msg);
                break;
            case 501: // 登陆失效
                store.dispatch("account/accountClearCache", "", { root: true });
                errMessageModal("请重新登录");
                handleLogout();
                break;
            case 500: // 登陆失效
                errMessageModal(responseData.msg);
                // return Promise.reject(responseData)
                // store.dispatch('account/accountClearCache', '', { root: true })
                // router.push({ name: 'login', query: { redirect: fullPath } })
                break;
            default:
                break;
        }
        return Promise.reject(responseData);
    },
    (error) => {
        // 请求失败
        if (error && error.response) {
            switch (error.response.status) {
                case 400:
                    error.message = "请求错误";
                    break;
                case 401:
                    error.message = "未授权，请登录";
                    break;
                case 403:
                    error.message = "拒绝访问";
                    break;
                case 404:
                    error.message = `请求地址出错: ${error.response.config.url}`;
                    break;
                case 408:
                    error.message = "请求超时";
                    break;
                case 500:
                    error.message = "服务器内部错误";
                    break;
                case 501:
                    error.message = "服务未实现";
                    break;
                case 502:
                    error.message = "服务器未响应";
                    break;
                case 503:
                    error.message = "服务不可用";
                    break;
                case 504:
                    error.message = "网关超时";
                    break;
                case 505:
                    error.message = "HTTP版本不受支持";
                    break;
                default:
                    break;
            }
        }
        errMessageModal(error.message);
        return Promise.reject(error);
    }
);

export default service;
