import container from "@src/layout/container"
import { meta } from "@src/layout/container/metaLayout"


let pre = "recruit-"
export default [
  {
    path: '/recruit',
    name: 'recruit',
    redirect: {
      name: `${pre}recruit`
    },
    component: container,
    children: [{
      path: 'index',
      name: `${pre}index`,
      meta: {
        title: "投标",
        header: true,
        auth: true,
        layout: meta({ activeTab: 'recruit' }).recruit,
      },
      component: () =>
        import ("@view/recruit")
    }]
  },
  {
    path: '/details',
    name: 'details',
    redirect: {
      name: `${pre}details`
    },
    component: container,
    children: [{
      path: 'index',
      name: `${pre}details-index`,
      meta: {
        title: "详情",
        header: true,
        auth: true,
        layout: meta().recruitDetails,
      },
      component: () =>
        import ("@view/recruit/details")
    }]
  },
  {
    path: '/project-bidding',
    name: 'project-bidding',
    redirect: {
      name: `${pre}project-bidding`
    },
    component: container,
    children: [{
      path: 'index',
      name: `${pre}project-bidding-index`,
      meta: {
        title: "工程招标",
        header: true,
        auth: true,
        layout: meta({ activeTab: 'project-bidding' }).recruit,
      },
      component: () =>
        import ("@view/recruit/projectBidding")
    }]
  },
  {
    path: '/government-procurement',
    name: 'government-procurement',
    redirect: {
      name: `${pre}government-procurement`
    },
    component: container,
    children: [{
      path: 'index',
      name: `${pre}government-procurement-index`,
      meta: {
        title: "政府采购",
        header: true,
        auth: true,
        layout: meta({ activeTab: 'government-procurement' }).recruit,
      },
      component: () =>
        import ("@view/recruit/governmentProcurement")
    }]
  },
  {
    path: '/subcontract-acquisition',
    name: 'subcontract-acquisition',
    redirect: {
      name: `${pre}subcontract-acquisition`
    },
    component: container,
    children: [{
      path: 'index',
      name: `${pre}subcontract-acquisition-index`,
      meta: {
        title: "分包集采",
        header: true,
        auth: true,
        layout: meta({ activeTab: 'subcontract-acquisition' }).recruit,
      },
      component: () =>
        import ("@view/recruit/subcontractAcquisition")
    }, {
      path: 'details',
      name: `${pre}details`,
      meta: {
        title: "分包集采详情",
        header: true,
        auth: true,
        layout: meta().recruitDetails,
      },
      component: () =>
        import ("@view/recruit/subcontractAcquisition/details")
    }]
  },
]
