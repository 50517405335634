<template>
  <div>
    <el-header class="header-area">
      <div class="flex-lr">
        <div class="flex a-mr11">
          <div>
            <el-image
              style="width: 30px; height: 30px; margin-top: 5px"
              src="https://oss.yanxin7.cn/2023-08-29/2f7aa9bf01b7656d6ca1139a3f4bd146"
            ></el-image>
          </div>
          <div class="company" @click="toPath('/websiteIndex/websiteIndex')">
            邦建通数字建筑平台
          </div>
        </div>
        <div class="flex">
          <div
            class="nav"
            :class="isActive == '/dataCenter/dataCenter' && 'is_active'"
            @click="toPath('/dataCenter/dataCenter')"
          >
            邦建通
          </div>
          <div class="nav" :class="isActive == '3' && 'is_active'">
            <a target="name" @click="toPath('smartAI')">智能AI</a>
          </div>
          <div class="nav" :class="isActive == '3' && 'is_active'">
            <a target="name" @click="toPath('/bxbh/#/login')">企业管理</a>
          </div>
          <div
            class="nav"
            :class="isActive == '/join/join' && 'is_active'"
            @click="toPath('/join/join')"
          >
            加盟合作
          </div>

          <div
            class="nav"
            :class="isActive == '/websiteIndex/websiteIndex' && 'is_active'"
            @click="toPath('/websiteIndex/websiteIndex')"
          >
            关于我们
          </div>
          <el-popover placement="bottom" width="120" trigger="hover">
            <el-button size="mini" class="member-btn" slot="reference">
              <div class="">
                <el-image
                  style="width: 78px; height: 24px; margin-top: 5px"
                  src="https://oss.yanxin7.cn/2023-07-03/daa7d55c2a20faf84682882048aaec38"
                ></el-image>
              </div>
            </el-button>
            <div class="member-type">
              <div
                style="margin-bottom: 1vw"
                @click="toPath(`/member/member?type=company`)"
              >
                企业会员
              </div>
              <div @click="toPath(`/member/member?type=person`)">个人会员</div>
            </div>
          </el-popover>
          <div v-if="!isLoggedIn">
            <div style="height: 24px">
              <button
                style="margin-top: 2px; line-height: 24px; white-space: nowrap;"
                class="btn"
                @click="doLogin"
              >
                登录/注册
              </button>
            </div>
          </div>
          <div v-if="isLoggedIn">
            <div class="back-btn">
              <el-popover
                placement="bottom"
                width="526"
                trigger="hover"
                @show="popoverShow"
              >
                <div
                  v-if="isSettleCompany"
                  style="
                    display: flex;
                    align-items: center;
                    margin-bottom: 18px;
                  "
                >
                  <div style="display: flex; align-items: center; flex: 1">
                    <div class="a-text-image">
                      {{ companyShortName || "-" }}
                    </div>
                    <div>
                      <div
                        class="text-line1"
                        style="font-size: 16px; margin-bottom: 6px"
                      >
                        {{ userInfos.companyName }}
                      </div>
                      <div
                        style="
                          font-size: 12px;
                          padding: 2px 8px;
                          border-radius: 10px;
                          width: fit-content;
                        "
                        :style="{
                          background: isComMember
                            ? 'linear-gradient(137deg, #F5A245 0%, #FFD182 100%)'
                            : '#F3F3F3',
                          color: isComMember ? '#fff' : '#888888',
                        }"
                      >
                        {{ isComMember ? "当前已是会员" : "当前暂无会员" }}
                      </div>
                    </div>
                  </div>
                  <el-button
                    type="text"
                    style="color: #888; font-size: 16px"
                    @click="changeEnterprise"
                  >
                    <i
                      class="el-icon-sort"
                      style="transform: rotateZ(90deg)"
                    ></i>
                    切换企业
                  </el-button>
                </div>
                <div style="display: flex">
                  <el-button class="member-box" @click="openMember('person')">
                    <div class="member-box-tips">开通</div>
                    <div class="title">
                      <el-image
                        style="width: 18px; height: 18px; margin-right: 4px"
                        :src="
                          isPersonMember
                            ? 'https://oss.yanxin7.cn/2023-08-31/73884cb5e343e339c69365852b74b857'
                            : 'https://oss.yanxin7.cn/2023-08-31/df2eb654f865f56fc247dd8cb72dcda4'
                        "
                      >
                      </el-image>
                      <span>个人会员</span>
                    </div>
                    <div>企业、招投标、项目信息…</div>
                  </el-button>
                  <el-button class="member-box" @click="openMember('company')">
                    <div class="member-box-tips">开通</div>
                    <div class="title">
                      <el-image
                        style="width: 18px; height: 18px; margin-right: 4px"
                        :src="
                          isComMember
                            ? 'https://oss.yanxin7.cn/2023-08-31/73884cb5e343e339c69365852b74b857'
                            : 'https://oss.yanxin7.cn/2023-08-31/df2eb654f865f56fc247dd8cb72dcda4'
                        "
                      >
                      </el-image>
                      <span>企业会员</span>
                    </div>
                    <div>企业、招投标、项目信息…</div>
                  </el-button>
                </div>

                <div>
                  <span style="color: #888888">常用服务</span>
                </div>
                <div class="menu-box">
                  <el-button
                    type="text"
                    class="menu-box-item-active"
                    @click="handleToPage('personalCenter')"
                  >
                    <el-image
                      src="https://oss.yanxin7.cn/2023-08-31/4f0af9f07b4c10636935a389ff579963"
                    ></el-image>
                    <div>个人中心</div>
                  </el-button>
                  <el-button
                    :class="
                      isSettleCompany ? 'menu-box-item-active' : 'menu-box-item'
                    "
                    type="text"
                    @click="handleToPage('enterCompany')"
                  >
                    <el-image
                      :src="
                        isSettleCompany
                          ? 'https://oss.yanxin7.cn/2023-08-31/ea1857fd7168e5d3cac1c832d3fd92ef'
                          : 'https://oss.yanxin7.cn/2023-08-31/3b9c720cb21e6547f27b5feb3db01f7c'
                      "
                    ></el-image>
                    <div>企业认证</div>
                  </el-button>
                  <el-button
                    :class="
                      isSettleUser ? 'menu-box-item-active' : 'menu-box-item'
                    "
                    type="text"
                    @click="handleToPage('enterPerson')"
                  >
                    <el-image
                      :src="
                        isSettleUser
                          ? 'https://oss.yanxin7.cn/2023-08-31/3e3efee2a56f911864ca5cc38e832092'
                          : 'https://oss.yanxin7.cn/2023-08-31/93d180114046490901ddf3ca03d938ee'
                      "
                    ></el-image>
                    <div>个人认证</div>
                  </el-button>
                  <el-button
                    class="menu-box-item"
                    type="text"
                    @click="loginOut"
                  >
                    <el-image
                      src="https://oss.yanxin7.cn/2023-08-31/b7d4d224ba909c5248e795a1415a7851"
                    ></el-image>
                    <div style="color: #4e4e4e">退出登录</div>
                  </el-button>
                </div>
                <!-- 未读消息 -->
                <template v-if="msgList.length">
                  <div
                    style="
                      margin-top: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span style="color: #888888"
                      >服务通知（{{ unreads || 0 }}条未读）</span
                    >
                    <el-button
                      type="text"
                      style="color: #2288fc; padding: 0"
                      @click="toMsgNotice"
                      >查看全部 ></el-button
                    >
                  </div>
                  <div
                    v-for="item in msgList"
                    :key="item.id"
                    class="notice-item"
                  >
                    <div
                      class="notice-item-tips"
                      :style="{
                        background: item.beRead === 0 ? '#F81E1E' : '#F8F8F8',
                      }"
                    ></div>
                    <div class="notice-item-content text-line1">
                      {{ item.title || item.content || "-" }}
                    </div>
                    <div style="color: #888888; flex-shrink: 0">
                      {{ item.createDate || "-" }}
                    </div>
                  </div>
                </template>

                <button class="btn-person" slot="reference">
                  <span>
                    <img class="img1" :src="headPortrait" />
                  </span>
                  <span>
                    <img
                      class="img2"
                      :src="require('@/assets/images/bjtsy/m12605.png')"
                  /></span>
                </button>
              </el-popover>
            </div>
          </div>
          <!-- 悬浮导航栏 -->
          <servicer></servicer>
          <div class="phone">
            <el-image
              style="height: 40px; width: 40px"
              src="https://oss.yanxin7.cn/2023-07-05/6159ce9a541555c79a526614fcef60d2"
            ></el-image>
            &nbsp;&nbsp;&nbsp; 0771-4985-999
          </div>
        </div>
      </div>
    </el-header>

    <el-dialog
      :visible.sync="dialogVisiable"
      :close-on-click-modal="false"
      :show-close="false"
      width="550px"
    >
      <div class="close-icon" @click="dialogVisiable = false">
        <i name="el-icon-close"></i>
      </div>
      <div class="enterprise-dialog" v-loading="enterpriseLoading">
        <div class="dialog-header">
          <img
            src="@/assets/bg/change-company-bg.png"
            style="width: 100%; height: 100%"
          />
          <div style="position: absolute; top: 50px; left: 30px">
            <div style="font-size: 30px; font-weight: 600">
              数字化转型升级解决方案
            </div>
            <div style="font-size: 14px; margin-top: 15px">
              请选择您的身份，以便我们更好的为您提供服务
            </div>
          </div>
        </div>
        <div style="padding: 30px 75px">
          <div style="padding-bottom: 20px">选择您要进入的身份：</div>
          <div class="choose-content">
            <div
              v-for="item in enterpriseList"
              :key="item.companyId"
              style="margin-bottom: 20px"
            >
              <el-button
                class="enterprise-item"
                @click="toSwitchEnterprise(item)"
                :class="{
                  'enterprise-item-active':
                    selectedCreditCode === item.creditCode,
                }"
              >
                <img
                  src="https://oss.yanxin7.cn/2023-09-01/eff4f3eb68ca7259a0413781ddf7a5ac"
                  v-show="selectedCreditCode === item.creditCode"
                  style="
                    width: 32px;
                    height: 32px;
                    position: absolute;
                    top: -2px;
                    right: -2px;
                  "
                />
                <div style="display: flex; justify-content: space-between">
                  <div class="text-line1">{{ item.companyName }}</div>
                  <div
                    style="font-size: 12px"
                    :style="{ color: item.memberLevel ? '#3292FD' : '#888' }"
                  >
                    {{ item.memberLevel ? "企业VIP会员" : "暂无会员" }}
                  </div>
                </div>
              </el-button>
            </div>
          </div>
          <div style="margin-top: 50px">
            <el-button
              style="width: 400px; background: #007ef2; color: #fff"
              @click="confirmSwitchEnterprise()"
              v-loading="switchLoading"
              >进入</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { settledEnterpriseList, switchEnterprise } from "@src/api/account.js";
import Utils from "@src/utils/shift.js";
import wxCommon from "@/utils/wxCommon";
import { isMember } from "@/api/member";
import { getMsgNoticePage, getMsgUnread } from "@/api/personalCenter";
export default {
  components: {},

  computed: {
    ...mapState("account", ["token", "userInfos"]),
    isLoggedIn() {
      return this.token && this.userInfos;
    },
    routeRedirect() {
      return this.$route.query.redirect;
    },

    isSettleCompany() {
      if (this.userInfos?.companyName) {
        return true;
      }
      return false;
    },

    isSettleUser() {
      if (this.userInfos?.realName) {
        return true;
      }
      return false;
    },
    companyShortName() {
      if (this.userInfos?.companyName) {
        return wxCommon.getShortName(this.userInfos.companyName);
      } else {
        return "";
      }
    },
    headPortrait() {
      return (
        this.userInfos?.headPortrait ||
        "https://oss.yanxin7.cn/2023-08-23/7da900523b43328139a18b69b1fba7ee"
      );
    },
  },

  data() {
    return {
      userInfo: {},
      codeurl: "",
      isRouterAlive: true,
      showLoginForm: false,
      path: "",
      activeTab: "home",
      searchValue: "",
      redirectPath: "",
      propsData: [
        { name: "入驻用户量：", value: "123", unit: "人" },
        { name: "今日访问量：", value: "124", unit: "人" },
        { name: "累计访问量：", value: "125", unit: "人" },
      ],
      propsConfig: {
        boxWidth: "80%",
      },
      menuList: [
        { label: "首页", name: "home" },
        { label: "资质查询", name: "qualification" },
        { label: "金融服务", name: "record" },
        { label: "招标投标", name: "personnel" },
        { label: "机械管理", name: "project" },
        { label: "造价咨询", name: "certificate" },
        { label: "智慧工地", name: "creditscore" },
      ],
      search: "",
      isSearch: "",
      routePath: "",

      unreads: 0,
      msgList: [],
      isPersonMember: false,
      isComMember: false,
      enterpriseLoading: false,
      enterpriseList: [],
      dialogVisiable: false,
      selectedCreditCode: null,
      switchLoading: false,
      isActive: "",
    };
  },
  beforeDestroy() {
    window.removeEventListener("setItem");
    window.removeEventListener("message");
  },
  mounted() {
    window.addEventListener("setItem", this.handleSessionStorageChange);
    let routePath = this.$route.path;
    console.log("routePath", routePath);
    this.routePath = routePath;
    this.isActive = routePath;
    if (this.isLoggedIn) {
      this.checkIsMember();
    }
    Utils.$on("loginSuccess", () => {
      this.checkIsMember();
    });

    let that = this;
    window.addEventListener("message", (e) => {
      if (e.data === "updateUserInfo") {
        that.$store.dispatch("account/getNewUserInfo");
      }
      if (e.data === "updateMemberData") {
        that.checkIsMember();
      }
    });
  },
  methods: {
    async checkIsMember() {
      try {
        let { data } = await isMember({
          compCode: this.userInfos?.creditCode,
        });
        this.isPersonMember = data.personMember ? true : false;
        this.isComMember = data.compMember ? true : false;
      } catch (err) {
        console.log("校验会员失败", err);
      }
    },

    openMember(type) {
      if (type === "person" && !this.isSettleUser) {
        this.$message.warning("请先实名认证");
      }
      if (type === "company" && !this.isSettleCompany) {
        this.$message.warning("请先注册企业");
        setTimeout(() => {
          this.$router.push("/enterCompany/enterCompany");
        }, 3000);
      }
      let url = `/member/member?type=${type}`;

      const path = this.$router.resolve({ path: url });
      window.open(path.href, "name");
    },

    // 登录信息弹窗
    popoverShow() {
      this.getMsgUnread();
      this.getMsgList();
    },

    async getMsgUnread() {
      if (!this.isLoggedIn) return;
      try {
        let { data } = await getMsgUnread({
          tarUserId: this.userInfos.userId,
          tarCreditCode: this.userInfos?.creditCode,
        });
        this.unreads = data;
      } catch (err) {
        this.unreads = 0;
      }
    },

    async getMsgList() {
      if (!this.isLoggedIn) return;
      try {
        let { data } = await getMsgNoticePage({
          current: 1,
          size: 2,
          tarUserId: this.userInfos.userId,
          beRead: 0,
        });
        this.msgList = data.records;
      } catch (err) {}
    },

    // 跳转页面
    handleToPage(type) {
      let url = "";
      if (type === "personalCenter") {
        url = "/personalCenter/personalCenter";
      }
      if (type === "enterCompany") {
        url = "/enterCompany/enterCompany";
      }
      if (type === "enterPerson") {
        if (this.userInfos?.realName) return;
        // 已经实名
        url = "/personalCenter/personEnter";
      }
      const currentPath = this.$router.currentRoute.path;
      if (!url || url === currentPath) return;

      const path = this.$router.resolve({
        path: url,
        query: { beforePage: currentPath },
      });

      window.open(path.href, "name");
    },

    toMsgNotice() {
      let url = this.$router.resolve({ path: "/personalCenter/msgNotice" });
      window.open(url.href, "name");
    },

    changeEnterprise() {
      this.getEnterpriseList();
      this.dialogVisiable = true;
      this.selectedCreditCode = this.userInfos.creditCode;
    },

    toSwitchEnterprise(item) {
      this.selectedCreditCode = item.creditCode;
    },

    async confirmSwitchEnterprise() {
      if (this.selectedCreditCode === this.userInfos.creditCode) {
        this.dialogVisiable = false;
      } else {
        try {
          this.switchLoading = true;
          await switchEnterprise({
            creditCode: this.selectedCreditCode,
          }).then(res=>{
                sessionStorage.setItem("loginType", res.data.loginType);
          });
          this.dialogVisiable = false;
          this.$message.success("切换成功");
          this.switchLoading = false;
     
          if (window?.opener) {
            window.opener.postMessage("updateUserInfo", "*");
            window.opener.postMessage("updateMemberData", "*");
          }
          await this.$store.dispatch("account/getNewUserInfo");
          this.checkIsMember();
        } catch (err) {
          console.log("切换失败", err);
          this.switchLoading = false;
          this.$message.error(err?.msg || "切换失败");
        }
      }
    },

    // 切换企业--获取企业列表
    async getEnterpriseList() {
      try {
        this.enterpriseLoading = true;
        let { data } = await settledEnterpriseList();
        data.forEach((item) => {
          item.memberLevel = item.settledEnterprise?.memberLevel || 0;
        });
        let defaultList = data.filter(
          (item) => item.creditCode === this.userInfos.creditCode
        );
        let otherList = data.filter(
          (item) => item.creditCode !== this.userInfos.creditCode
        );
        this.enterpriseList = [...defaultList, ...otherList];
        this.enterpriseLoading = false;
      } catch (err) {
        this.enterpriseLoading = false;
      }
    },

    // 登出
    loginOut() {
      this.clearAllCookies();
    },

    // 清除所有Cookies
    clearAllCookies() {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var cookieName = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie =
          cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      }
      this.$store.dispatch("account/accountLogout");
      this.$message({
        message: "退出成功！",
        type: "success",
      });
      setTimeout(() => {
        this.$router.replace("/");
      }, 1500);
    },

    handleSessionStorageChange() {
      let needLogin = sessionStorage.getItem("needLogin");
      console.log("sessionStorage value changed:", needLogin);
      let nlInfo = JSON.parse(needLogin);
      if (nlInfo && nlInfo.value) {
        this.redirectPath = nlInfo.redirect || "/";
        this.doLogin();
      }
    },
    toPath(url) {
      if(url == 'smartAI'){
        window.open(`https://pc.isi.yanxin7.cn/`, 'smartAI')
        return
      }
      let routePath = this.$route.path;
      this.routePath = routePath;
      console.log("this.routePath", this.routePath);
      console.log("url", url);
      
      this.$router.push("/dataCenter/dataCenter");
      if (
        url == "/member/member?type=company" &&
        url.includes(this.routePath)
      ) {
        Utils.$emit("changMemberType", "company");
        return;
      }
      if (url == "/member/member?type=person" && url.includes(this.routePath)) {
        Utils.$emit("changMemberType", "person");
        return;
      }
      if (url == "/websiteIndex/websiteIndex") {
        let vRouter = this.$router.resolve({
          path: url,
          params: {},
        });
        window.open(vRouter.href, "websiteIndex");
        return;
      }
      let theUrlPrefix = window.location.origin;
      if (window.location.origin.includes("trial.yanxin7.cn")) {
        theUrlPrefix = "https://trial.yanxin7.cn";
      }
      if (
        window.location.origin.includes("dev.yanxin7.cn") ||
        window.location.origin.includes("localhost")
      ) {
        theUrlPrefix = "https://dev.yanxin7.cn";
      }
      if (url == "/bxbh/#/login") {
        if (!this.isSettleCompany && this.isLoggedIn) {
          this.$message.warning("请先注册企业");
          return;
        }
        window.open(`${theUrlPrefix}/bxbh/#/insurance/home/index`, '首页')
        return;
      }

      if (url.includes(this.routePath)) {
        location.reload();
        return;
      }
      if (url == "/about/about") {
        let vRouter = this.$router.resolve({
          path: url,
          params: {},
        });
        window.open(vRouter.href, "name1");
        return;
      }
      if (url == "/dataCenter/dataCenter") {
        let vRouter = this.$router.resolve({
          path: url,
          params: {},
        });
        window.open(vRouter.href, "name2");
        return;
      }
      if (url.includes("/member/member")) {
        let vRouter = this.$router.resolve({
          path: url,
          params: {},
        });
        window.open(vRouter.href, "name3");
        return;
      }

      let token = this.$store.state.account.token;
      if (
        !token &&
        url !== "/join/join" &&
        url !== "/websiteIndex/websiteIndex"
      ) {
        this.showLoginForm = true;
        return;
      }
      let vRouter = this.$router.resolve({
        path: url,
        params: {},
      });
      window.open(vRouter.href, "name4");
    },
    doLogin() {
      this.$emit("handLogin");
    },
  },
};
</script>

<style scoped lang="less">
.header-area {
  padding-top: 10px;
  height: 60px !important;
  background: #ffffff !important;
  box-shadow: 0px 3px 6px 1px rgba(3, 96, 230, 0.06);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}

.company {
  cursor: pointer;
  font-size: 1.5rem;
  font-family: Source Han Sans CN-Regular;
  font-weight: bold;
  color: #007ef2;
  /* margin-right: 302px; */
  margin-left: 10px;
  white-space: nowrap;
}

.nav {
  font-size: 20px;
  font-family: Source Han Sans CN-Regular;
  color: #212121;
  cursor: pointer;
  margin-right: 50px;
  white-space: nowrap;
}

.btn {
  height: 24px;
  font-size: 14px;
  background-color: #007ef2;
  border-radius: 4px 4px;
  color: #ffffff;
  text-align: center;
  line-height: 26px;
  border: none;
  cursor: pointer;
}

.flex {
  display: flex;
  align-self: start;
  align-items: center;
  justify-content: center;
}

/* 靠左-上 */
.flex-t {
  display: flex;
  align-self: start;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  cursor: pointer;
}

/* 靠左-右 */
.flex-lr {
  display: flex;
  align-self: start;
  align-items: center;
  justify-content: space-between;
}

.back-btn {
  margin-left: 14px;
}

.img1 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.img2 {
  margin-left: 12px;
  padding-bottom: 8px;
}

.txt-fs {
  font-size: 18px;
  padding-left: 10px;
}

.img-t1 {
  width: 18px;
  height: 18px;
}

.list-t {
  padding-top: 3px;
  margin-right: 5px;
}

.btn-person {
  background-color: #ffffff;
  width: 70px;
  padding-top: 3px;
  border: none;
  cursor: pointer;
}

.member-btn {
  overflow: hidden;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  margin-right: 30px;
}

.member-type {
  width: 5vw;
  margin-left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-weight: bolder;
  cursor: pointer;
}

.mb-10 {
  margin-bottom: 10px;
}

/* // */
::v-deep .el-tabs--border-card > .el-tabs__content {
  padding: 0px;
}

.min-w {
  min-width: 1450px;
}

::v-deep .el-header {
}

/* 菜单箭头 */
::v-deep .el-tabs__nav-next,
::v-deep .el-tabs__nav-prev {
  position: absolute;
  cursor: pointer;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  color: #ffffff;
}

::v-deep .el-tabs__nav-next.is-disabled,
::v-deep .el-tabs__nav-prev.is-disabled {
  color: #ffffff;
}

::v-deep .el-tabs--border-card > .el-tabs__header {
  /* background-color: #F5F7FA; */
  /* border-bottom: 1px solid #E4E7ED; */
  margin: 0;
}

::v-deep
  .el-tabs--bottom
  .el-tabs--left
  > .el-tabs__header
  .el-tabs__item:nth-child(2),
.el-tabs--bottom .el-tabs--right > .el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom.el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:nth-child(2),
.el-tabs--bottom.el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--left > .el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--right > .el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top.el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:nth-child(2),
.el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(2) {
  padding-left: 0px;
}

.page-view {
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}

::v-deep .el-tabs--border-card > .el-tabs__header {
  border-bottom: none;
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  margin-top: 23px;
}

.info h1 {
  margin-bottom: 20px;
  font-weight: bolder;
}

.info-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info-left {
  margin-right: 40px;
}

.info-left_video {
  width: 600px;
  height: 338px;
  opacity: 1;
}

.info-left_list {
  width: 600px;
  height: 338px;
  opacity: 1;
}

.list-icon {
  width: 7px;
  height: 7px;
  background: #2288fc;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-right: 24px;
}

.list-item_text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 416px;
}

.info-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 39px;
  cursor: pointer;
}

.info-list-item:hover {
  color: #2288fc;
}

.service-box {
  display: flex;
  width: 1200px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.service-item {
  width: 226px;
  height: 96px;
  background: #ffffff;
  box-shadow: 3px 3px 16px 1px rgba(61, 145, 255, 0.16);
  opacity: 1;
  border: 1px solid #e2e5ec;
  margin-bottom: 18px;
  padding: 16px 13px;
}

.service-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.service-text {
  width: 80px;
  font-size: 20px;
  font-weight: 400;
}

.service-item:hover {
  cursor: pointer;

  .service-text {
    color: #2288fc;
  }
}

.parent-item {
  width: 180px;
  margin-bottom: 18px;
}

a {
  text-decoration: none;
  color: black;
}

.phone {
  display: flex;
  align-items: center;
  margin-left: 50px;
  white-space: nowrap;
}

.a-text-image {
  width: 40px;
  height: 40px;
  background: #2180ef;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 17px;
  margin-right: 10px;
  flex-shrink: 0;
}

.menu-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  background: #f8f8f8;
  border-radius: 4px;
  margin-top: 10px;
}

.menu-box-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  padding: 0;
  color: #4e4e4e;

  .el-image {
    width: 24px;
    height: 24px;
    margin-bottom: 4px;
  }
}

.menu-box-item-active {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  padding: 0;
  color: #2288fc;

  .el-image {
    width: 24px;
    height: 24px;
    margin-bottom: 4px;
  }
}

.menu-box-item:hover {
  color: #4e4e4e;
}

.menu-box-item-active:hover {
  color: #2288fc;
}

.notice-item {
  margin-top: 10px;
  background: #f8f8f8;
  border-radius: 4px;
  padding: 13px 10px;
  display: flex;
  align-items: center;

  .notice-item-tips {
    width: 6px;
    height: 6px;
    background: #f81e1e;
    border-radius: 6px;
    margin-right: 10px;
    flex-shrink: 0;
  }

  .notice-item-content {
    padding-right: 10px;
    font-size: 14px;
    font-weight: 600;
    flex: 1;
  }
}

.text-line1 {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
}

.member-box + .member-box {
  margin-left: 20px;
}

.member-box:hover {
  border: none;
  color: #888888;
}

.member-box {
  padding: 10px;
  background: #f9fcff;
  border-radius: 4px;
  flex: 1;
  position: relative;
  margin-bottom: 20px;
  border: none;

  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: #212121;
  }

  .desc {
    color: #888888;
    font-size: 14px;
  }

  .member-box-tips {
    background: linear-gradient(137deg, #f5a245 0%, #ffd182 100%);
    padding: 4px 8px;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    right: 10px;
    top: 10px;
    position: absolute;
    font-weight: bold;
  }
}

::v-deep .el-dialog {
  border-radius: 20px;
}

::v-deep .el-dialog__header {
  display: none;
}

::v-deep .el-dialog__body {
  padding: 0;
}

.enterprise-dialog {
  width: 550px;
  background: #fff;
  text-align: left;
  border-radius: 20px;
  overflow: hidden;

  .dialog-header {
    width: 550px;
    height: 150px;
    position: relative;
    color: #007ef2;
    text-align: left;
  }

  .choose-content {
    height: 250px;
    overflow-y: scroll;

    .enterprise-item {
      width: 400px;
      text-align: left;
      padding: 18px 20px;
      border-width: 2px;
      color: #212121;
      font-size: 16px;
      position: relative;
    }

    .enterprise-item-active {
      border-color: #2288fc;
    }
  }
}
.is_active {
  color: #2288fc;
}
::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  height: 0 !important;
  -webkit-appearance: none;
  background: transparent;
  color: transparent;
}

.close-icon {
  position: absolute;
  width: 20px;
  height: 20px;
  cursor: pointer;
  right: -20px;
  top: -10px;
}

.close-icon:before,
.close-icon:after {
  position: absolute;
  content: "";
  height: 20px;
  width: 2px;
  background-color: #c5c5c5;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.close-icon:before {
  transform: rotate(45deg);
}

.close-icon:after {
  transform: rotate(-45deg);
}

.close-icon:hover:before,
.close-icon:hover:after {
  background-color: white;
}
</style>
