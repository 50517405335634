/**
 * 邮箱
 * @param {*} s
 */
export function isEmail(s) {
    return /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(
        s
    );
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile(s) {
    return /^1[0-9]{10}$/.test(s);
}

/**
 * 电话号码
 * @param {*} s
 */
export function isPhone(s) {
    var reg = /^1(3|4|5|6|7|8|9)\d{9}$/;
    if (reg.test(s)) {
        return true;
    } else {
        return false;
    }
}

/**
 * URL地址
 * @param {*} s
 */
export function isURL(s) {
    return /^http[s]?:\/\/.*/.test(s);
}

/**
 * 价格
 * @param {*} s
 */
export function isPrice(s) {
    return /^\d+(\.\d{1,2})?$/.test(s);
}

// 校验身份证号
export function isIdCard(idCardNumber) {
    let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if (reg.test(idCardNumber)) {
        return true;
    } else {
        return false;
    }
}
