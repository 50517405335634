import layout from "@src/layout";
import home from "./modules/home";
import qualification from "./modules/qualification";
import record from "./modules/record";
import personnel from "./modules/personnel";
import project from "./modules/project";
import certificate from "./modules/certificate";
import creditscore from "./modules/creditscore";
import recruit from "./modules/recruit";
import management from "./modules/management";
import engineering from "./modules/engineering";
import websiteIndex from "./modules/websiteIndex";
import about from "./modules/about";
import join from "./modules/join";
import dataCenter from "./modules/dataCenter";
import ecosystem from "./modules/ecosystem";
import member from "./modules/member";
import subwebsite from "./modules/subwebsite";
import indexes from "./modules/indexes";
import moreNew from "./modules/moreNew";
import personalCenter from "./modules/personalCenter";
import enterCompany from "./modules/enterCompany";
import enterpriseViews from "./modules/enterpriseViews";
import combinedQuery from "./modules/combinedQuery"


// 在框架内的路由
const frameIn = [
    {
        path: "/",
        name: "root",
        component: layout,
        redirect: "/dataCenter/dataCenter",
    },
    ecosystem,
    home,
    qualification,
    record,
    personnel,
    project,
    certificate,
    creditscore,
    ...recruit,
    ...management,
    ...engineering,
    websiteIndex,
    join,
    dataCenter,
    about,
    member,
    subwebsite,
    indexes,
    moreNew,
    personalCenter,
    enterCompany,
    enterpriseViews,
    combinedQuery,

  
];

export default [...frameIn];
