import layout from "@src/fristPageLayout";
let pre = "personalCenter-";
export default {
    path: "/personalCenter",
    name: "personalCenter",
    redirect: {
        name: `${pre}personalCenter`,
    },
    component: layout,
    children: [
        {
            path: "personalCenter",
            name: `${pre}personalCenter`,
            meta: {
                title: "个人中心",
                header: true,
                auth: true,
            },
            component: () => import("@view/personalCenter/personalInfo/personalInfo"),
        },
        {
            path: "editInfo",
            name: `${pre}editInfo`,
            meta: {
                title: "编辑信息",
                header: true,
                auth: true,
            },
            component: () => import("@view/personalCenter/editInfo/editInfo"),
        },
        {
            path: "enterpriseList",
            name: `${pre}enterpriseList`,
            meta: {
                title: "企业列表",
                header: true,
                auth: true,
            },
            component: () => import("@view/personalCenter/enterpriseList/enterpriseList"),
        },
        {
            path: "personEnter",
            name: `${pre}personEnter`,
            meta: {
                title: "个人认证",
                header: true,
                auth: true,
            },
            component: () => import("@view/personalCenter/personEnter/personEnter"),
        },

        {
            path: "msgNotice",
            name: `${pre}msgNotice`,
            meta: {
                title: "消息通知",
                header: true,
                auth: true,
            },
            component: () => import("@view/personalCenter/msgNotice/msgNotice"),
        },


        
    ],
};
