<template>
  <el-container class="min-w">
    <!-- <topHeader></topHeader> -->
    <!-- 头部 -->
    <div id="mainScrollArea" style="padding: 0px 0 0;width:100%;">
      <div class="main-header">
        <div class="search-for">

          <div class="sub-title ">
            <div class="sub-z"></div>
            <div class="sub-lr">施工企业资质业绩查询系统</div>
            <div class="sub-r"></div>
          </div>

        </div>
        <!-- 菜单 -->
        <div class="nav-width">
          <div style="display: flex;justify-content: center;">
            <div style="width: 1520px;display: flex;">
              <div class="tabItem" :class="tabIndex === index && 'tabItemActive'" @click="selectTab({ item, index })"
                v-for="(item, index) in menuList" :key="index">{{ item.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <statistics-number
        :propsData="propsData"
        :propsConfig="propsConfig"
      ></statistics-number> -->

      <div class="page-view">
        <keep-alive>
          <!--2023 10 07改动 原来旧的写法 好像并不能起到缓存作用-->
          <!-- <router-view v-if="isRouterAlive" :key="path"></router-view> -->
          <router-view v-if="$route.meta.keepAlive" :key="path"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" :key="path"></router-view>

      </div>

      <login-form v-if="showLoginForm" :routeRedirect="routeRedirect" @loginSuccess="handleLoginSuccess"
        @close="handleLoginClose" />
      <Footer style="width: 100%; margin: auto"></Footer>

    </div>
  </el-container>
</template>
<script>
import { mapState } from "vuex";
import topHeader from "@/components/topHeader";
import StatisticsNumber from "@/components/statistics-number";
import Footer from "@/components/footer";
import LoginForm from "@/components/login-form";
import { qrUserInfo } from "@src/api/account.js";
import {
  urlBrowseRegUserStatTheme1,
  urlBrowseUserStatTheme1
} from "@/api/engineering";
export default {
  components: {
    topHeader,
    Footer,
    StatisticsNumber,
    LoginForm,
  },

  computed: {
    ...mapState("system", ["visitTotal", "registerUserTotal", "todayVisiNum"]),
    ...mapState("account", ["token", "userInfos"]),
    isLoggedIn() {
      return this.token && this.userInfos;
    },
    routeRedirect() {
      return this.$route.query.redirect;
    },
  },
  data() {
    return {
      userInfo: {
        name: "aeqw",
      },
      routePath: '',
      codeurl: "",
      isRouterAlive: true,
      showLoginForm: false,
      path: "",
      activeTab: "home",
      searchValue: "",
      redirectPath: "",
      propsData: [
        { name: "入驻用户量：", value: 0, unit: "人" },
        { name: "今日访问量：", value: 0, unit: "人" },
        { name: "累计访问量：", value: 0, unit: "人" },
      ],
      propsConfig: {
        boxWidth: "80%",
      },
      menuList: [
        { label: "首页", name: "home" },
        { label: "组合查询", name: "qualification" },
        { label: "资质查询", name: "qualification" },
        { label: "备案查询", name: "record" },
        { label: "人员查询", name: "personnel" },
        { label: "工程项目", name: "project" },
        { label: "电子证照", name: "certificate" },
        { label: "信用分", name: "creditscore" },
      ],
      search: "",
      isSearch: "",
      tabIndex: '',

    };
  },
  watch: {
    $route: {
      handler(to, from) {
        this.path = to.path;
        this.getRouter();
        let fromPath = from ? from.path : "";
        console.log("fromPath=" + fromPath);
        console.log("toPath=" + this.path);
        if (this.path) {
          //监听组件 跳转选中
          let index = this.menuList.findIndex(item => item.path === to.path)
          this.tabIndex = index === -1 ? '' : index

          // let paths = this.path.split("/");
          // if (paths.length > 1 && paths[1]) this.activeTab = paths[1];
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    window.removeEventListener("setItem", this.handleSessionStorageChange);
  },
  mounted() {
    this.getUrlBrowseUserStatTheme1()
    this.getUrlVieweUserStatTheme1()
    window.addEventListener("setItem", this.handleSessionStorageChange);
    // let routePath = this.$route.path;
    // console.log("routePath=" + routePath);
    // if (routePath) {
    //   let paths = routePath.split("/");
    //   if (paths.length > 1 && paths[1]) this.activeTab = paths[1];
    // }
    this.tryQrUserInfo();
  },
  methods: {
    // 登出
    loginOut() {
      this.clearAllCookies();
    },
    // 清除所有Cookies
    clearAllCookies() {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var cookieName = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie =
          cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      }
      this.$message({
        message: "退出成功！",
        type: "success",
      });
      setTimeout(() => {
        location.reload(); // 强制刷新页面
      }, 1500);
    },
    // 注册用户数
    async getUrlBrowseUserStatTheme1() {

      try {
        const { data } = await urlBrowseRegUserStatTheme1({});
        this.propsData[0].value = data.total
        this.$store.commit("system/setRegisterUserTotal", data.total);
      } catch (error) {
        console.log("urlBrowseRegUserStatTheme1", error);
      }
    },
    // 总访问量
    async getUrlVieweUserStatTheme1() {
      try {
        const { data } = await urlBrowseUserStatTheme1({});
        this.propsData[1].value = data.todayTotal
        this.propsData[2].value = data.total
        this.$store.commit("system/setVisitTotal", data.total);
      } catch (error) {
        console.log("urlBrowseRegUserStatTheme1", error);
      }
    },
    getRouter() {
      this.isSearch = this.$route?.meta?.layout?.isSearch;
      let isTabs = this.$route?.meta?.layout?.isTabs;
      if (isTabs) {
        this.menuList = [
          { label: "首页", name: "recruit" },
          { label: "工程招标", name: "project-bidding" },
          { label: "政府采购", name: "government-procurement" },
          { label: "分包集采", name: "subcontract-acquisition" },
        ];
      } else {
        this.menuList = [
          { label: "首页", path: "/enterprise/home" },
          { label: "组合查询", path: "/combinedQuery/combinedForm" },
          { label: "资质查询", path: "/qualification/index" },
          { label: "备案查询", path: "/record/index" },
          { label: "人员查询", path: "/personnel/index" },
          { label: "工程项目", path: "/project/index" },
          { label: "电子证照", path: "/certificate/index" },
          { label: "信用分", path: "/creditscore/index" },
        ];
      }
    },
    tryQrUserInfo() {
      var query = this.$route.query;
      if (query.code && query.state) {
        this.qrUserInfo(this.$route.query.code, this.$route.query.state);
      }
    },
    scanCodeLogin() {
      let that = this;
      this.$store.commit("account/setUserInfos", this.userInfo);
      this.$store.commit("account/setToken", this.userInfo.token);

      var toPage = that.$route.query.redirect || "/";
      console.log("toPage===>", toPage);
      that.$router.replace(toPage);
    },
    // 获取用户信息
    qrUserInfo(code, state) {
      qrUserInfo({ code, state }).then((res) => {
        window.history.replaceState(null, null, this.getnewurl())
        this.userInfo = res.data;
        this.scanCodeLogin();
      });
    },
    getnewurl() {
      let url = document.location.href
      let reg = /[^\w](code|state)=?([^&|^#]*)/g;
      url = url.replace(reg, "");
      let paramsIndex = url.indexOf("&")
      if (paramsIndex !== -1) {
        url = url.substring(0, paramsIndex) + "?" + url.substring(paramsIndex + 1, url.length)
      }
      return url;
    },
    handleSessionStorageChange() {
      let needLogin = sessionStorage.getItem("needLogin");
      console.log("sessionStorage value changed:", needLogin);
      let nlInfo = JSON.parse(needLogin);
      if (nlInfo && nlInfo.value) {
        this.redirectPath = nlInfo.redirect || "/";
        this.doLogin();
      }
    },
    toPath(url) {
      let token = this.$store.state.account.token;
      if (!token) {
        this.showLoginForm = true;
        return;
      }
      let vRouter = this.$router.resolve({
        path: url,
        params: {},
      });
      window.open(vRouter.href, "_blank");
    },
    handleLoginSuccess(toPage) {
      let redirectPath = this.redirectPath || toPage;
      this.resetSetItem("needLogin", JSON.stringify({ value: false }));
      this.showLoginForm = false;
      // this.$router.replace(toPage);
      console.log("handleLoginSuccess-->toPage=" + redirectPath);
      this.redirectPath = "/";
      this.$router.push({ path: redirectPath });
    },
    handleLoginClose() {
      this.showLoginForm = false;
      this.activeTab = "home";
      if (this.redirectPath != "/") {
        this.redirectPath = "/";
        this.$router.push({ path: this.redirectPath });
      }
    },
    doLogin() {
      // this.$router.push({ path: "/login" });
      this.showLoginForm = true;
    },
    onSearch() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
        this.$router.push({
          path: this.$route.path,
          query: { search: this.search },
        });
      });
    },
    doSearch(searchView, searchValue) {
      let oldActiveTab = this.activeTab;
      let oldSearchValue = this.searchValue;
      if (searchView == oldActiveTab && searchValue == oldSearchValue) {
        this.isRouterAlive = false;
        this.$nextTick(() => {
          this.isRouterAlive = true;
          this.routerPush(this.activeTab, searchValue);
        });
      } else {
        this.activeTab = searchView;
        this.searchValue = searchValue;
        this.routerPush(this.activeTab, searchValue);
      }
    },

    selectTab({ item, index }) {
      if (this.tabIndex === index) return
      if (!item.path) {
        return this.$message({
          message: '功能开发中,敬请期待~',
          type: 'warning',
          center: true
        });
      }
      this.tabIndex = index

      this.$router.push({ path: item.path, query: {} })

    },
    handleTabClick(tab) {
      console.log(tab.name);
      this.routerPush(tab.name);
    },
    routerPush(pathName, searchValue) {
      let param = ''
      if (pathName === 'home') {
        param = { path: "/enterprise/home" };
      } else {
        param = { path: "/" + pathName + "/index" };
      }

      if (searchValue) {
        param.query = { name: searchValue };
      }
      console.log("routerPush.param", param);
      this.$router.push(param);
    },
    scrollToTop() {
      const mainElement = document.getElementById("mainScrollArea");
      mainElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style scoped lang="less">
.header-area {
  min-width: 1580px;
  height: 60px !important;
  background: #ffffff !important;
  box-shadow: 0px 3px 6px 1px rgba(3, 96, 230, 0.06);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
}

.main_search {
  width: 900px;
  margin: 0 auto;

  .main_search_position {
    position: relative;
    height: 50px;

    .main_search_position_input {
      height: 100%;

      /deep/ .el-input__inner {
        height: 100%;
        padding-right: 131px;
        font-size: 18px;
      }
    }

    .main_search_position_btn {
      width: 112px;
      position: absolute;
      top: 4px;
      right: 4px;
      bottom: 4px;
      font-size: 18px;
    }
  }
}

.company {
  cursor: pointer;
  font-size: 24px;
  font-family: Source Han Sans CN-Regular;
  font-weight: bold;
  color: #007ef2;
  /* margin-right: 302px; */
  margin-left: 10px;
}

.nav {
  font-size: 20px;
  font-family: Source Han Sans CN-Regular;
  font-weight: 500;
  color: #212121;
  cursor: pointer;
}

.btn {
  width: 60px;
  height: 24px;
  font-size: 14px;
  background-color: #007ef2;
  border-radius: 4px 4px;
  color: #ffffff;
  text-align: center;
  line-height: 26px;
  border: none;
  cursor: pointer;
}

/* ::v-deep .el-button{
    padding: 0px;
    line-height: 0px;
} */
.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.menu-conetnt {
  height: 100vh;
  box-sizing: border-box;
  position: relative;
}

.menu-conetnt::-webkit-scrollbar {
  width: 0 !important;
}

.el-container {
  height: 100vh;
}

.el-main {
  overflow-x: hidden;
}

.el-aside {
  position: relative;
  background: #2c2d31;
}

/* 头部背景图 */
.main-header {
  background-image: url(~@/assets/images/enterprise/query_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
  height: 200px;
  position: relative;
  display: flex;
  align-items: center;

}

.search-for {
  width: 100%;
  /* height: 330px !important; */
  color: white;
  text-align: center;
}

.main-title {
  font-size: 34px;
  font-family: Source Han Sans CN-Regular;
  font-weight: bold;
  color: #ffffff;
  padding-top: 50px;
  letter-spacing: 40px;
  letter-spacing: 7px;
}

.sub-title {
  font-size: 34px;
  font-family: Source Han Sans CN-Regular;
  font-weight: 400;
  color: #ffffff;
  padding-top: 15px;
  padding-bottom: 40px;
  letter-spacing: 7px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.sub-access {
  width: 100%;
  height: 60px;
  color: #4e4e4e;
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(3, 96, 230, 0.06);
  opacity: 1;
}

.sub-g {
  height: 22px;
  width: 170px;
  font-size: 14px;
  text-align: left;
  border-right: 2px solid #4e4e4e;
  font-family: Source Han Sans CN-Regular;
}

.sub-no {
  width: 190px;
  height: 22px;
}

.col-c {
  font-size: 18px;
  font-weight: bold;
  color: #007ef2;
}

.ab-slot {
  position: absolute;
  top: 320px;
  width: 100%;
}

.flex {
  display: flex;
  align-self: start;
  align-items: center;
  justify-content: center;
}

/* 靠左-上 */
.flex-t {
  display: flex;
  align-self: start;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  cursor: pointer;
}

/* 靠左-右 */
.flex-lr {
  padding-left: 19vw;
  display: flex;
  align-self: start;
  align-items: center;
  justify-content: flex-start;
}

.lr-10 {
  padding-left: 10%;
  padding-right: 10%;
}

.sub-z {
  width: 100px;
  height: 3px;
  background: linear-gradient(270deg, #ffffff 0%, #1890ff 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}

.sub-lr {
  font-size: 30px;
  font-weight: bold;
  margin-left: 20px;
  margin-right: 20px;
}

.sub-r {
  width: 100px;
  height: 3px;
  background: linear-gradient(90deg, #ffffff 0%, #1890ff 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}

/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.a-page-enter-active {
  transition: all 0.3s ease;
}

.a-page-leave-active {
  transition: all 0.3s ease;
}

.a-page-enter {
  transform: translateX(-30px);
  opacity: 0;
}

.a-page-enter-to {
  transform: translateX(0);
  opacity: 1;
}

.a-page-leave

/* .slide-fade-leave-active for below version 2.1.8 */
  {
  transform: translateX(-30px);
  opacity: 1;
}

.a-page-leave-to

/* .slide-fade-leave-active for below version 2.1.8 */
  {
  transform: translateX(30px);
  opacity: 0;
}

#content ::v-deep .el-tabs__nav-scroll {
  /* width: 80%; */
  margin: 0 auto;
}

::v-deep #content.el-tabs--border-card {
  border: none;
}

::v-deep #content.el-tabs--border-card>.el-tabs__header {
  background-color: #0460e6;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  color: white;
  background-color: #0662e6;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  margin-top: 0px;
}

::v-deep #content.el-tabs--border-card>.el-tabs__header {
  height: 60px;
}

::v-deep .el-tabs__nav.is-stretch>* {
  flex: 1;
  text-align: center;
  width: 150px;
  height: 60px;
  font-size: 18px;
  line-height: 60px;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  font-weight: bold;
}

.nav-width {
  position: absolute;
  bottom: 0;
  height: 40px;
  width: 100%;

  /* margin: 0 auto; */
  background-color: #013c8233;
}

::v-deep .nav-width #content.el-tabs--border-card>.el-tabs__header {
  background-color: transparent;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  background-color: transparent;
}

::v-deep .nav-width #content.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  background-color: white !important;
  color: #007ef2 !important;
}

.el-tabs--border-card {
  background: transparent;
  box-shadow: none;
}

.nav-left {
  margin-left: 10%;
  margin-right: 10%;
}

.nav {
  font-size: 20px;
  font-family: Source Han Sans CN-Regular;
  font-weight: 500;
  color: #212121;
  cursor: pointer;
  margin-right: 2vw;
  font-size: 18px;
}

/* 浮窗样式 */
.suspended_box {
  position: fixed;
  bottom: 400px;
  z-index: 20;
  right: 20px;
}

.kefu {
  font-family: Source Han Sans CN-Regular;
  box-sizing: border-box;
  width: 60px;
  height: 224px;
  background: #ffffff;
  box-shadow: 0px 3px 16px 1px rgba(41, 96, 222, 0.16);
  border-radius: 6px 6px;
  position: relative;
  box-sizing: border-box;
}

.qrcode_content {
  position: absolute;
  bottom: 32%;
  z-index: 20;
  right: 100px;
  display: none;
}

.gzh_content {
  position: absolute;
  bottom: -2%;
  z-index: 20;
  right: 100px;
  display: none;
}

.business_content {
  position: absolute;
  bottom: -35%;
  z-index: 20;
  right: 100px;
  display: none;
}

.pp {
  width: 165px;
  height: 165px;
  margin-top: 19px;
  margin-left: 17px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
}

.to_top {
  width: 60px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 3px 16px 1px rgba(41, 96, 222, 0.16);
  border-radius: 6px;
  padding-top: 4px;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  color: #333e4d;
  cursor: pointer;
}

.to_top_img {
  width: 60px !important;
  /* height: 60px !important; */
}

.to_top_img img {
  width: 30px !important;
  height: 30px !important;
}

.qr_1 {
  width: 60px;
  height: 75px;
}

.pt-22 {
  padding-top: 11px;
}

.qr_1:hover {
  background-color: #eff2f8;
  border-radius: 6px 6px;
}

.qr_1:hover+.qrcode_content {
  display: block;
}

.qr_code {
  width: 30px;
  height: 30px;
  background: url(~@/assets/images/bjtsy/mbz11844.png) no-repeat;
  background-size: cover;
  margin-bottom: 1px;
  /* margin-top: 20px; */
  margin-left: 15px;
  cursor: pointer;
  /* position:relative */
}

.qr_code2 {
  width: 30px;
  height: 30px;
  background: url(~@/assets/images/bjtsy/mbz11846.png) no-repeat;
  background-size: cover;
  margin-bottom: 1px;
  /* margin-top: 20px; */
  margin-left: 15px;
  cursor: pointer;
  /* position:relative */
}

.qr_code3 {
  width: 30px;
  height: 30px;
  background: url(~@/assets/images/bjtsy/mbz11845.png) no-repeat;
  background-size: cover;
  margin-bottom: 1px;
  margin-left: 15px;
  cursor: pointer;
}

.code_c {
  font-size: 14px;
  text-align: center;
}

.erweima {
  text-align: center;
  width: 200px;
  height: 240px;
  top: -7px;
  /* left: -100px; */
  background: #fff;
  /* border: 1px solid #CCC; */
  float: right;
  cursor: default !important;
  box-shadow: 0px 3px 16px 1px rgba(41, 96, 222, 0.16);
  border-radius: 6px 6px;
}

.qr_2 {
  width: 60px;
  height: 75px;
}

.qr_2:hover {
  background-color: #eff2f8;
  border-radius: 6px 6px;
}

.qr_2:hover+.gzh_content {
  display: block;
}

.member-type {
  width: 5vw;
  margin-left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-weight: bolder;
  cursor: pointer;
}

.qr_3:hover {
  background-color: #eff2f8;
  border-radius: 6px 6px;
}

.qr_3:hover+.business_content {
  display: block;
}

.qr_3 {
  width: 60px;
  height: 75px;
}

.msg {
  text-align: left;
  margin-left: 32px;
  margin-top: 40px;
  font-size: 16px;
  color: #5c6681;
}

::v-deep .el-backtop {
  width: 20px;
}

.msg_2 {
  width: 150px;
  text-align: left;
  margin-left: 32px;
  margin-top: 8px;
  font-weight: bold;
  font-size: 18px;
}

.fw-b {
  font-weight: bold;
}

.fz-19 {
  font-size: 19px;
}

.back-btn {
  margin-left: 44px;
}

.img1 {
  width: 30px;
  height: 30px;
}

.img2 {
  margin-left: 12px;
  padding-bottom: 8px;
}

.txt-fs {
  font-size: 18px;
  padding-left: 10px;
}

.img-t1 {
  width: 18px;
  height: 18px;
}

.list-t {
  padding-top: 3px;
  margin-right: 5px;
}

.btn-person {
  background-color: #ffffff;
  width: 70px;
  padding-top: 3px;
  border: none;
  cursor: pointer;
}

.mb-10 {
  margin-bottom: 10px;
}

/* // */
::v-deep .el-tabs--border-card>.el-tabs__content {
  padding: 0px;
}

.min-w {
  min-width: 1580px;

}

::v-deep .el-header {
  padding: 10px 0px 0px 0px;
}

/* 菜单箭头 */
::v-deep .el-tabs__nav-next,
::v-deep .el-tabs__nav-prev {
  position: absolute;
  cursor: pointer;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  color: #ffffff;
}

::v-deep .el-tabs__nav-next.is-disabled,
::v-deep .el-tabs__nav-prev.is-disabled {
  color: #ffffff;
}

::v-deep .el-tabs--border-card>.el-tabs__header {
  /* background-color: #F5F7FA; */
  /* border-bottom: 1px solid #E4E7ED; */
  margin: 0;
}

::v-deep .el-tabs--bottom .el-tabs--left>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom .el-tabs--right>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom.el-tabs--border-card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--left>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--right>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top.el-tabs--border-card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2) {
  padding-left: 0px;
}

.page-view {
  width: 80%;
  margin: 0 auto;
  margin-top: 16px;
}

::v-deep .el-tabs--border-card>.el-tabs__header {
  border-bottom: none;
}

.a-mr11 {
  margin-right: 11vw;
}

a {
  text-decoration: none;
  color: black;
}

.phone {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.is_active {
  color: #2288FC;
}

.member-btn {
  overflow: hidden;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
}

.tabList {
  display: flex;
  align-items: center;

}

.tabItem {
  color: #ffffff;
  padding-left: 45px;
  padding-right: 45px;
  font-size: 14px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tabItem:hover {
  background: #FFFFFF;
  background: rgba(255, 255, 255, 0.3);
}

.tabItem:hover.tabItemActive {
  background: #ffffff;
  color: #1890FF;
}

.tabItemActive {
  background: #ffffff;
  color: #1890FF;

}
</style>
