import layout from '@src/fristPageLayout'
let pre = 'enterprise-'
export default {
  path: '/enterprise',
  name: 'enterprise',
  redirect: {
    name: `${pre}enterprise`,
  },
  component: layout,
  children: [
    {
      path: 'home',
      name: `${pre}home`,
      meta: {
        title: '首页',
        header: true,
        auth: false,
      },
      component: () => import('@view/enterpriseViews/home'),
    },
    {
      path: 'query',
      name: `${pre}query`,
      meta: {
        title: '查询系统',
        header: true,
        auth: false,
      },
      component: () => import('@view/enterpriseViews/query'),
    },

    {
      path: 'enterpriseDetail',
      name: `${pre}enterpriseDetail`,
      meta: {
        title: '企业详情',
        header: true,
        auth: false,
      },
      component: () => import('@view/enterpriseViews/details/enterpriseDetail'),
    },
  
    {
      path: 'newsInfoLIst',
      name: `${pre}newsInfoLIst`,
      meta: {
        title: '建筑资讯',
        header: true,
        auth: false,
      },
      component: () => import('@view/enterpriseViews/newsInfoLIst'),
    },
    {
      path: 'newsDetail',
      name: `${pre}newsDetail`,
      meta: {
        title: '资讯详情',
        header: true,
        auth: false,
      },
      component: () => import('@view/enterpriseViews/newsDetail'),
    },
    {
      path: 'dynamicList',
      name: `${pre}dynamicList`,
      meta: {
        title: '动态列表',
        header: true,
        auth: false,
      },
      component: () => import('@view/enterpriseViews/dynamicList'),
    },
    {
      path: 'dynamicDetail',
      name: `${pre}dynamicDetail`,
      meta: {
        title: '动态详情',
        header: true,
        auth: false,
      },
      component: () => import('@view/enterpriseViews/dynamicDetail'),
    },
  ],
}