import layout from "@src/layout"
let pre = "creditscore-"
export default {
    path: '/creditscore',
    name: 'creditscore',
    redirect: {
        name: `${pre}creditscore`
    },
    component: layout,
    children: [{
        path: 'index',
        name: `${pre}index`,
        meta: {
            title: "信用分",
            header: true,
            auth: true,
        },
        component: () =>
            import ("@view/creditscore")
    }]
}