import layout from '@src/fristPageLayout'
let pre = 'indexes-'
export default {
  path: '/indexes',
  name: 'indexes',
  redirect: {
    name: `${pre}indexes`,
  },
  component: layout,
  children: [
    {
      path: 'indexes',
      name: `${pre}indexes`,
      meta: {
        title: '首页',
        header: true,
        auth: false,
      },
      component: () => import('@view/indexes'),
    }
  ],
}
