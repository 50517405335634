import request from '@src/plugins/request'

// 造价数据列表
export const urlCecnNewsPage = function (data) {
  return request({
    url: `/cecn/news/page?${data}`,
    method: 'GET',
  })
}
// 造价定额详情
export const urlCecnNewsDetail = function (data) {
  return request({
    url: `/cecn/news/detail?${data}`,
    method: 'GET',
  })
}
// 软件分类
export const urlCommonCategoryGetTypeIsTree = function (data) {
  return request({
    url: `/commonCategory/getTypeIsTree?${data}`,
    method: 'GET',
  })
}
// 软件列表
export const urlSoftwareMrgPage = function (data) {
  return request({
    url: `/software/mrg/page`,
    method: 'POST',
    data,
  })
}
// 造价趋势时间
export const urlCecnNewsGetSubsTime = function (data) {
  return request({
    url: `/cecn/news/getSubsTime?${data}`,
    method: 'GET',
  })
}
// 造价趋势层级
export const urlCecnNewsGetSubsType = function (data) {
  return request({
    url: `/cecn/news/getSubsType?${data}`,
    method: 'GET',
  })
}
// 造价趋势
export const urlCecnNewsGetReportAreaData = function (data) {
  return request({
    url: `/cecn/news/getReportAreaData?${data}`,
    method: 'GET',
  })
}

//造价预算人员-列表
export const getCreativePersonnelPage = function (data) {
  return request({
    url: `/cost/budget/business/page`,
    method: 'POST',
    data,
  })
}
// 造价预算人员-详情
export const getBusinessDetail = function (data) {
  return request({
    url: `/cost/budget/business/byId`,
    method: 'get',
    params: data,
  })
}
// ----------
export const getBudgetPerson = function (data) {
  return request({
    url: `cost/budget/business/getBudgetPerson`,
    method: 'get',
    params: data,
  })
}
//工作年限
export const getWorkAgesList = function (data) {
  return request({
    url: `/cost/budget/business/getWorkAges`,
    method: 'get',
    params: data,
  })
}
// 造价信息搜索
export const urlProjectPriceInfoEsPriceInfo = function (data) {
  return request({
    url: `/projectPriceInfo/es/priceInfo`,
    method: 'POST',
    data,
  })
}

// 热门行情tab
export const urlPriceHotMarket = function (data) {
  return request({
    url: `/price/hotMarket?${data}`,
    method: 'GET'
  })
}
// 造价公司-列表
export const getEnterpriseHighPage = function (data) {
  return request({
    url: `/dcEnterprise/high/page`,
    method: 'POST',
    data,
  })
}
// 造价公司-列表详情
export const getEnterpriseHighDetail = function (data) {
  return request({
    url: `/dcEnterprise/detail`,
    method: 'POST',
    data,
  })
}
// 热门行情折线图
export const urlPriceTrend = function (data) {
  return request({
    url: `/price/trend?${data}`,
    method: 'GET'
  })
}
// 最新期刊
export const urlPriceNewPeriodicalList = function (data) {
  return request({
    url: `/price/newPeriodicalList?${data}`,
    method: 'GET'
  })
}
// 最新行情
export const urlPriceNewestMarketList = function (data) {
  return request({
    url: `/price/newestMarketList?${data}`,
    method: 'GET'
  })
}
// 各地住宅走势
export const urlCecnNewsGetReportCityData = function (data) {
  return request({
    url: `/cecn/news/getReportCityData?${data}`,
    method: 'GET',
  })
}
// 全国住宅成本走势
export const urlCecnNewsGetReportData = function (data) {
  return request({
    url: `/cecn/news/getReportData?${data || ''}`,
    method: 'GET',
  })
}
// 各地住宅地区
export const urlCecnNewsGetReportCityList = function (data) {
  return request({
    url: `/cecn/news/getReportCityList?${data || ''}`,
    method: 'GET',
  })
}
// 城市住宅信息
export const urlCecnNewsGetReportDown = function (data) {
  return request({
    url: `/cecn/news/getReportDown?${data}`,
    method: 'GET',
  })
}
// 材料分类
export const urlProjectPriceInfoEsGetType34 = function (data) {
  return request({
    url: `/projectPriceInfo/es/getType34`,
    method: 'POST',
    data,
  })
}
// 信息价数据详情
export const urlProjectPriceInfoEsMaterialPriceInfo = function (data) {
  return request({
    url: `/projectPriceInfo/es/material/priceInfo`,
    method: 'POST',
    data,
  })
}
// 定额收藏
export const urlCommonCollectionAddOrDel = function (data) {
  return request({
    url: `/common/collection/addOrDel`,
    method: 'POST',
    data,
  })
}
// 定额收藏判断
export const urlCommonCollectionCheck = function (data) {
  return request({
    url: `/common/collection/check`,
    method: 'POST',
    data,
  })
}
// 信息价详情
export const urlProjectPriceInfoEsDetails = function (data) {
  return request({
    url: `/projectPriceInfo/es/details`,
    method: 'GET',
    params: data,
  })
}
// 信息价详情数据列表
export const urlProjectPriceInfoEsRecently = function (data) {
  return request({
    url: `/projectPriceInfo/es/recently`,
    method: 'GET',
    params: data,
  })
}
// 信息价详情数据列表
export const urlDcEnterpriseHighPage = function (data) {
  return request({
    url: `/dcEnterprise/high/page`,
    method: 'POST',
    data,
  })
}
// 总访问量
export const urlBrowseUserStatTheme1 = function (data) {
  return request({
    url: `/browse/user/stat/theme1`,
    method: 'GET',
    data,
  })
}
// 入驻企业总数
export const urlBrowseEntStatTheme1 = function (data) {
  return request({
    url: `/browse/ent/stat/theme1`,
    method: 'GET',
    data,
  })
}
// 注册用户总数
export const urlBrowseRegUserStatTheme1 = function (data) {
  return request({
    url: `/browse/regUser/stat/theme1`,
    method: 'GET',
    data,
  })
}
// 热门询价
export const urlEnquiryHotCostMaterials = function (data) {
  return request({
    url: `/enquiry/hot/cost/materials`,
    method: 'POST',
    data
  })
}
// 公共分类
export const urlCommonCategoryGetAppidIsTree = function (data) {
  return request({
    url: `/commonCategory/getAppidIsTree`,
    method: 'GET',
    params: data,
  })
}
// 栏目列表
export const urlCommonColumnList = function (data) {
  return request({
    url: `/common/column/list`,
    method: 'GET',
    params: data,
  })
}

// 获取系统介绍
export const urlSystemList = function (data) {
  return request({
    url: `/m-system-introduce/list`,
    method: 'GET',
    params: data,
  })
}


// 首页标语
export const urlAdvertisingAdLaunchAppTitle = function (data) {
  return request({
    url: `/advertising/adLaunchApp/title`,
    method: 'GET',
    params: data,
  })
}

// 造价信息搜索
export const urlProjectPriceInfoEsPriceInfoList = function (data) {
  return request({
    url: `/cost-url-region/page`,
    method: 'POST',
    data,
  })
}

// 分类
export const getCatory = function (data) {
  return request({
    url: `/commonCategory/listTree`,
    method: 'POST',
    data,
  })
}
// 获取资讯列表
export const industryInformation = function (data) {
  return request({
    url: `/industryInformation/industryInformationInfoList`,
    method: 'POST',
    data,
  })
}
// 获取行业资讯分类
export const industryInformationCalssList = function (data) {
  return request({
      url: `/industryInformation/industryInformationCalssList`,
      method: 'POST',
      data,
  })
}
// 获取行业资讯分页
export const getIndustryInformationPage = function (data) {
  return request({
      url: `/industryInformation/page`,
      method: 'POST',
      data,
  })
}

// 数据中心动态数据
export const infoV2 = function (data) {
  return request({
    url: `/base/page/databases/infoV2`,
    method: 'GET',
    params: data,
  })
}

// 当日访问数据
export const theme1 = function (data) {
  return request({
    url: `/browse/user/stat/theme1`,
    method: 'GET',
    params: data,
  })
}

// 入驻企业总是
export const theme2 = function (data) {
  return request({
    url: `/browse/ent/stat/theme2`,
    method: 'GET',
    params: data,
  })
}

// 入驻企业总是
export const personNum = function (data) {
  return request({
    url: `/esHome/tags/enterprise/person/count`,
    method: 'POST',
    data,
  })
}
// 招标项目数据
export const biddingCount = function (data) {
  return request({
    url: `/esHome/tags/tender/count`,
    method: 'POST',
    data,
  })
}

// 申请加盟
export const addPartnerApply = function (data) {
  return request({
    url: `/partnerApply/addPartnerApply`,
    method: 'POST',
    data,
  })
}

// 发生短信
export const NEWsendVerifyCode = function (data) {
  return request({
    url: `/common/NEWsendVerifyCode`,
    method: 'GET',
    params: data,
  })
}

// 系统介绍
export const systemList = function (data) {
  return request({
    url: `/m-system-introduce/list`,
    method: 'GET',
    params: data,
  })
}

// 操作手册
export const operationManul = function (data) {
  return request({
    url: `/bmanual/pageShow`,
    method: 'POST',
   data,
  })
}

// 校验验证码
export const checkVerifyCode = function (data) {
  return request({
    url: `/common/checkVerifyCode`,
    method: 'POST',
    data,
  })
}

// 公司列表
export const sysCompanyList = function (data) {
  return request({
    url: `/sysCompany/list`,
    method: 'POST',
    data,
  })
}

// 操作手册列表
export const bmanualTree = function (data) {
  return request({
    url: `/bmanual/tree`,
    method: 'POST',
    data,
  })
}

// 操作手册详情
export const bmanualDetail = function (data) {
  return request({
    url: `/bmanual/detail`,
    method: 'GET',
    params: data,
  })
}
// 操作手册详情
export const systemProductList = function (data) {
  return request({
    url: `/m-system-introduce/list`,
    method: 'GET',
    params: data,
  })
}

// 获取所有小程序的接口
export const AdLaunchApp = function (data) {
  return request({
    url: `/ad/AdLaunchApp/page`,
    method: 'POST',
    data,
  })
}

// 各地信息
export const newsPage = function (data) {
  return request({
    url: `/cecn/news/page`,
    method: 'GET',
    params: data,
  })
}

// 获取今年访问数据
export const getToYearData = function (data) {
  return request({
    url: `/browse/user/stat/theme2`,
    method: 'GET',
    params: data,
  })
}
