import layout from '@src/fristPageLayout'
let pre = 'subwebsite-'
export default {
  path: '/subwebsite',
  name: 'subwebsite',
  redirect: {
    name: `${pre}subwebsite`,
  },
  component: layout,
  children: [
    {
      path: 'subwebsite',
      name: `${pre}subwebsite`,
      meta: {
        title: '首页',
        header: true,
        auth: false,
      },
      component: () => import('@view/subwebsite'),
    },
    {
      path: 'ecosystem',
      name: `${pre}ecosystem`,
      meta: {
        title: '首页',
        header: true,
        auth: false,
      },
      component: () => import('@view/subwebsite/ecosystem'),
    },
    {
      path: 'partner',
      name: `${pre}partner`,
      meta: {
        title: '首页',
        header: true,
        auth: false,
      },
      component: () => import('@view/subwebsite/partner'),
    },
    {
      path: 'news',
      name: `${pre}news`,
      meta: {
        title: '首页',
        header: true,
        auth: false,
      },
      component: () => import('@view/subwebsite/news'),
    },
    {
      path: 'certificate',
      name: `${pre}certificate`,
      meta: {
        title: '首页',
        header: true,
        auth: false,
      },
      component: () => import('@view/subwebsite/certificate'),
    },
    {
      path: 'about',
      name: `${pre}about`,
      meta: {
        title: '首页',
        header: true,
        auth: false,
      },
      component: () => import('@view/subwebsite/about'),
    },
  ],
}
