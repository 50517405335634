import layout from "@src/layout"
let pre = "project-"
export default {
    path: '/project',
    name: 'project',
    redirect: {
        name: `${pre}project`
    },
    component: layout,
    children: [{
        path: 'index',
        name: `${pre}index`,
        meta: {
            title: "工程项目",
            header: true,
            auth: true,
        },
        component: () =>
            import ("@view/project")
    }, {
      path: 'details',
      name: `${pre}details`,
      meta: {
        title: '工程项目详情',
        header: true,
        auth: true,
      },
      component: () => import ("@view/project/details")
    }]
}