/**
 * 业务配置
 * **/
 const env = process.env.NODE_ENV;

 const Setting = {
     // 网页标题的后缀
     titleSuffix: env == 'development' ? '首页' : '首页',
     // 路由模式
     routerMode: 'hash',
     // 应用的基路径
     routerBase: '/',
     // 接口请求地址
     // apiBaseURL: env == 'development' ? '/zhou' : '/bxbh-api', //金融企业端
    //   apiBaseURL: env == 'development' ? '/zhao' : '/bxbh-api', // 本地接口
     //  apiBaseURL: env == 'development' ? '/testApi' : '/bxbh-api', // 正式服
     //   apiBaseURL: env == 'development' ? '/trial' : '/bxbh-api', // 正式服
     // apiBaseURL: env == 'development' ? '/qin' : '/bxbh-api', // 本地接口
     // apiBaseURL: env == 'development' ? '/dev' : '/bxbh-api', // 开发接口  保险保函 
    //   apiBaseURL: env == 'development' ? '/lpz' : '/bxbh-api', // 李工
     //  apiBaseURL: env == 'development' ? '/dev' : '/bxbh-api', // 开发接口1
     apiBaseURL: env == 'development' ? '/dev' : '/bxbh-api', // 开发接口
     // agreementHttp: env == 'development' ? '/api' : '/material', //协议访问地址
 
     // 腾讯地图接口请求地址
     mapApiURL: env == 'development' ? '/mapApi' : 'https://apis.map.qq.com',
     // 接口统一请求头
     apiHeaders: { 'X-Requested-With': 'XMLHttpRequest' },
     // 腾讯地图定位的Key
     tencentKey: "GQNBZ-MKFKU-637VL-4XQ7O-4UXH7-HUFGC",
     // 腾讯地图定位的name
     tencentName: "photo-Andro",
     // 接口请求返回错误时，弹窗的类型，可选值为 Message 或 Notice
     errorModalType: 'Message',
     // 弹窗显示时间
     errorModalDuration: 5000,
     // Cookies 缓存token后缀
     tokenKey: 'shiro-Session'
 
 }
 
 module.exports = Setting;