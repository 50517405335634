<template>
  <el-container style="overflow-x: hidden;">
    <!--头部-->
    <topHeader></topHeader>
    <el-main style="margin-top:20px">
      <el-scrollbar class="page-component__scroll">
        <!--页面公共头部-->
        <main-header :configData="configData" :styleData="styleData" @onSearch="onSearch"
          @handleTabClick="handleTabClick"></main-header>
        <!--路由-->
        <div class="main_router_view">
          <router-view v-if="isRouterAlive" :key="path"></router-view>
        </div>
        <!--底部公共板块-->
        <Footer style="width: 100%; min-width: 1520px; margin: auto" />
      </el-scrollbar>
      <!--返回顶部-->
      <el-backtop target=".page-component__scroll .el-scrollbar__wrap"></el-backtop>
    </el-main>
  </el-container>
</template>

<script>
import topHeader from "@/components/topHeader";
import { mapState } from "vuex";
import Header from '@/layout/container/header';
import MainHeader from '@/layout/container/main-header';
import Footer from "@/layout/Footer";
import { qrUserInfo } from "@/api/account.js";
import { urlBrowseUserStatTheme1, urlBrowseEntStatTheme1, urlBrowseRegUserStatTheme1 } from '@/api/engineering'
export default {
  name: "container",
  components: { aHeader: Header, MainHeader, Footer, topHeader },
  data() {
    return {
      configData: {},
      styleData: {},
      isRouterAlive: true,
      path: '',
      isSearch: false,
      statisticsList: []
    }
  },
  computed: {
    ...mapState("account", ["token", "userInfos"]),
    isLoggedIn() {
      return this.token && this.userInfos;
    },
    routeRedirect() {
      return this.$route.query.redirect;
    }
  },
  watch: {
    $route: {
      handler(to, from) {
        this.path = to.path;
        this.getRouter();
      },
      immediate: true
    },
  },
  beforeDestroy() {
    window.removeEventListener("setItem", this.handleSessionStorageChange);
  },
  mounted() {
    window.addEventListener("setItem", this.handleSessionStorageChange);
    this.tryQrUserInfo();
  },
  methods: {
    getBrowseStatTheme() {
      const promises = [
        urlBrowseUserStatTheme1(),
        urlBrowseEntStatTheme1(),
        urlBrowseRegUserStatTheme1()
      ];
      return Promise.all(promises)
    },
    async getStatistics() {
      const { configData, styleData } = this.$route?.meta?.layout;
      if (configData?.statistics?.show) {
        const resAll = await this.getBrowseStatTheme()
        this.statisticsList = resAll
        resAll.forEach((res, index) => {
          configData.statistics.list[index].value = res.data.total
        });
      }
      this.configData = configData;
      this.styleData = styleData;
    },

    getRouter() {
      const { configData, styleData } = this.$route?.meta?.layout;
      if (configData?.statistics?.show) {
        if (!this.statisticsList.length) {
          this.getStatistics()
        } else {
          this.statisticsList.forEach((res, index) => {
            configData.statistics.list[index].value = res.data.total
          });
        }
      }
      this.configData = configData;
      this.styleData = styleData;
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      })
      if (this.path && configData?.menu?.activeTab) {
        let paths = this.path.split("/");
        this.$set(this.configData.menu, 'activeTab', paths[1]);
      }
    },
    tryQrUserInfo() {
      var query = this.$route.query;
      if (query.code && query.state) {
        this.qrUserInfo(this.$route.query.code, this.$route.query.state);
      }
    },
    scanCodeLogin() {
      let that = this;
      this.$store.commit("account/setToken", this.userInfo.token);
      this.$store.dispatch("account/getNewUserInfo")
      var toPage = that.$route.query.redirect || "/";
      that.$router.replace(toPage);
    },
    // 获取用户信息
    qrUserInfo(code, state) {
      qrUserInfo({ code, state }).then((res) => {
        this.userInfo = res.data;
        window.history.replaceState(null, null, this.getnewurl())
        this.scanCodeLogin();
      });
    },
    getnewurl() {
      let url = document.location.href
      let reg = /[^\w](code|state)=?([^&|^#]*)/g;
      url = url.replace(reg, "");
      let paramsIndex = url.indexOf("&")
      if (paramsIndex !== -1) {
        url = url.substring(0, paramsIndex) + "?" + url.substring(paramsIndex + 1, url.length)
      }
      return url;
    },
    handleSessionStorageChange() {
      let needLogin = sessionStorage.getItem("needLogin");
      let nlInfo = JSON.parse(needLogin);
      if (nlInfo && nlInfo.value) {
        this.redirectPath = nlInfo.redirect || "/";
        this.doLogin();
      }
    },
    toPath(url) {
      let vRouter = this.$router.resolve({
        path: url,
        params: {},
      });
      window.open(vRouter.href, "_blank");
    },
    handleLoginSuccess(toPage) {
      let redirectPath = this.redirectPath || toPage;
      this.resetSetItem("needLogin", JSON.stringify({ value: false }));
      this.showLoginForm = false;
      // this.$router.replace(toPage);
      this.redirectPath = "/";
      this.$router.push({ path: redirectPath });
    },
    handleLoginClose() {
      this.showLoginForm = false;
      this.activeTab = "home";
      if (this.redirectPath != "/") {
        this.redirectPath = "/";
        this.$router.push({ path: this.redirectPath });
      }
    },
    doLogin() {
      // this.$router.push({ path: "/login" });
      this.showLoginForm = true;
    },



    doSearch(searchView, searchValue) {
      let oldActiveTab = this.activeTab;
      let oldSearchValue = this.searchValue;
      if (searchView == oldActiveTab && searchValue == oldSearchValue) {
        this.isRouterAlive = false;
        this.$nextTick(() => {
          this.isRouterAlive = true;
          this.routerPush(this.activeTab, searchValue);
        });
      } else {
        this.activeTab = searchView;
        this.searchValue = searchValue;
        this.routerPush(this.activeTab, searchValue);
      }
    },
    routerPush(pathName, searchValue) {
      let param = { path: "/" + pathName + "/index" };
      if (searchValue) {
        param.query = { name: searchValue };
      }
      this.$router.push(param);
    },
    handleTabClick(e, label) {
      document.title = label + '-邦建通';
      this.routerPush(e.value);
    },
    onSearch(e) {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
        // this.$router.push({path: '/engineering/index', query: { search: e.value }});
        this.$router.push({ path: this.$route.path, query: { search: e.value } });
      });
    }
  }
}
</script>

<style scoped lang="less">
* {
  box-sizing: border-box;
}

/deep/ .el-scrollbar__wrap {
  height: 100vh;
  overflow-y: auto;
}

.el-container,
.el-main,
.page-component__scroll {
  height: 100%;
}

.header_multi {
  padding-left: 40px;

  .header_multi_vip {
    width: 78px;
    min-width: 78px;
    height: 24px;
    cursor: pointer;
  }

  .header_multi_link {
    width: 2px;
    height: 20px;
    margin: 0 10px;
    background-color: #DCDCDC;
  }

  .login_user {
    cursor: pointer;
  }
}

.el-main {
  padding: 40px 0 0;

  .main_placeholder {
    height: 40px;
  }

  .main_router_view {
    width: 1520px;
    padding: 20px 0;
    margin: 0 auto;
  }
}
</style>
