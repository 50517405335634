<template>
    <i  class="icon iconfont "  :class="type" :style="{'color':color,'font-size': fontSize + 'px'}"></i>
  </template>
  
  <script>
  export default {
    props: {
      type: {
        type: String,
        default: "",
      },
      color: {
        type: String,
        default: "",
      },
      fontSize:{
        type:Number,
        default:16
      }
    },
  };
  </script>
  <style>
  .icon{
  margin-right: 3rpx;
  }
  </style>