import layout from '@src/fristPageLayout'
let pre = 'member-'
export default {
  path: '/member',
  name: 'member',
  redirect: {
    name: `${pre}member`,
  },
  component: layout,
  children: [
    {
      path: 'member',
      name: `${pre}member`,
      meta: {
        title: '首页',
        header: true,
        auth: false,
      },
      component: () => import('@view/member'),
    },
 
  ],
}
