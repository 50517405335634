import {
    accountAuthLogin,
    accountAuthLogout,
    accountAuthUpdate,
    getRegion,
    info,
} from "@api/account";
import cache from "@src/libs/util.cache.js";
import Setting from "@src/setting";
import { getUserInfo } from "@/api/common";
export default {
    namespaced: true,
    state: {
        token: cache.getCookies(Setting.tokenKey) || "",

        roleList: cache.getSessionStorage("roleList") || null,
        regionList: [],
        userInfos: cache.getSessionStorage("userInfos") || null,
    },
    mutations: {
        // 保存token
        setToken(state, token) {
            var inTwoHours = new Date(new Date().getTime() + 120 * 60 * 1000);
            cache.setCookies(Setting.tokenKey, token, { expires: inTwoHours });
            state.token = token;
        },
        // 保存权限列表
        setRoleList(state, roleList) {
            cache.setSessionStorage("roleList", roleList);
            state.roleList = roleList;
        },
        // 保存区域数据
        setRegion(state, list) {
            state.regionList = list;
        },
        // 保存用户信息
        setUserInfos(state, userData) {
            cache.setSessionStorage("userInfos", userData);
            state.userInfos = userData;
        },
    },
    actions: {
        /**
         * @description 用户登录
         * @param {String} username 用户名
         * @param {String} pwd 密码
         * @param {String} code 验证码
         * **/
        accountLogin(
            { state, commit, dispatch },
            { username = "", password = "", captcha = "", type = "", bySms = 0 }
        ) {
            return new Promise((resolve, reject) => {
                accountAuthLogin({ username, password, captcha,  bySms })
                    .then(async (res) => {
                        sessionStorage.setItem("loginType", res.data.loginType);
                        await commit("setToken", res.data.token);
                        await commit("setRoleList", res.data.roleList);
                        dispatch("getNewUserInfo");
                        //await dispatch("menu/getMenuList", res.data, { root: true });
                        resolve(res);
                    })
                    .catch((err) => {
                        console.log(err);
                        reject(err);
                    });
            });
        },
        /**
         * @description 获取区域数据
         * **/
        getRegion({ state, commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                getRegion(data)
                    .then(async (res) => {
                        await commit("setRegion", res.data);
                        resolve(res);
                    })
                    .catch((err) => {
                        console.log(err);
                        reject(err);
                    });
            });
        },
        /**
         * @description 退出登录
         * @param {String} username 用户名
         * @param {String} pwd 密码
         * @param {String} code 验证码
         * **/
        accountLogout({ state, commit, dispatch }) {
            return new Promise((resolve, reject) => {
                accountAuthLogout({})
                    .then(async (res) => {
                        await dispatch("accountClearCache");
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        /**
         * @description 清除数据和缓存
         *
         * **/
        accountClearCache({ state, commit }) {
            return new Promise(async (resolve, reject) => {
                await commit("setToken", "");
                await commit("setUserInfos", null);
                await commit("setRoleList", "");
                resolve();
            });
        },
  
        getNewUserInfo({ commit }) {
            return new Promise(async (resolve) => {
                try {
                    let { data } = await getUserInfo();
                    commit("setUserInfos", data);
                    resolve(data);
                } catch (err) {
                    resolve({});
                }
            });
        },
    },
};
