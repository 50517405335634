<template>
  <div>
    <div class="dibu">
      <div class="footer-box">
        <div class="f-left">
          <div class="flex-start" style="font-size: 18px; margin-bottom: 20px">
            <div class="">
              <a
                target="_blank"
                href="https://api.yanxin7.cn/api/user/SysTerms/get?type=yinsizhengce"
                >隐私政策</a
              >
              &nbsp;&nbsp;
            </div>
            <div class="shuxian">|</div>
            <div class="msg" @click="handMap">地图导航</div>
            <div class="shuxian">|</div>
            <div class="msg">使用索引</div>
          </div>

          <div class="flex-cloumn-start">
            <div
              style="margin-bottom: 18px; display: flex; align-items: center"
            >
              <img
                style="height: 18px; width: 18px; margin-right: 10px"
                :src="require('@/assets/images/gh20.png')"
                alt=""
              />
              <a href="https://beian.mps.gov.cn/#/query/webSearch?code=45010302002968" rel="noreferrer" target="_blank">桂公网安备45010302002968号</a >
            </div>
            <div style="margin-bottom: 18px;cursor: pointer;">
                <a href="https://beian.miit.gov.cn/#/Integrated/index" rel="noreferrer" target="_blank">网站备案&nbsp;&nbsp;&nbsp;&nbsp; 桂ICP备2022006745号</a>
            </div>
            <div style="margin-bottom: 18px">
              企业名称&nbsp;&nbsp;&nbsp;&nbsp;
              <a href="#">邦建通科技集团股份有限公司</a>
            </div>
            <div style="margin-bottom: 15px" @click="handMap">
              企业地址&nbsp;&nbsp;&nbsp;&nbsp;南宁市青秀区中柬路9号利海国际
            </div>
          </div>
        </div>
        <div class="footer-yinsi">
          <div
            style="padding-left: 70px; margin-bottom: 26px"
            class="flex-between"
          >
            <div>
              入驻企业：&nbsp;<span style="color: #fee021"
                >{{ totalCompany || "-" }} 家
              </span>
            </div>
            <div>|</div>
            <div>
              今日访问量：&nbsp;<span style="color: #fee021"
                >{{ todayTotal || "-" }} 人</span
              >
            </div>
            <div>|</div>
            <div>
              累计访问量 &nbsp;&nbsp;&nbsp;：<span style="color: #fee021"
                >{{ totalView || "-" }} 人</span
              >
            </div>
          </div>

          <div class="app-list flex-between">
            <div v-for="(item, index) in applist" :key="index">
              <div class="app-item">
                <el-image
                  style="width: 116px; height: 116px"
                  :src="item.url"
                ></el-image>
              </div>
              <div style="text-align: center; margin-left: 16px">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  urlBrowseRegUserStatTheme1,
  urlBrowseUserStatTheme1,
  theme1,
  theme2,
} from "@/api/engineering";
export default {
  data() {
    return {
      totalCompany: 0,
      todayTotal: 0,
      totalView: 0,
      // userViewTotal: 0,
      activeTab: "tab1",
      searchValue: "",
      // registerUserTotal:0,
      applist: [
        {
          name: "苹果APP下载",
          url: "https://oss.yanxin7.cn/2023-07-06/c88ed5c0ab30743e167e4f7b94ed6c84",
        },
        {
          name: "安卓APP下载",
          url: "https://oss.yanxin7.cn/2024-07-18/623c740f995289ec2d17be812c927e6b",
        },
        {
          name: "小程序",
          url: "https://oss.yanxin7.cn/2023-07-06/2e5a11aeb47dbca0a2616940d62a4274",
        },
        {
          name: "公众号",
          url: "https://oss.yanxin7.cn/2023-08-10/6bc03eaabc3e35df8f2a609ec55a4cb1",
        },
        {
          name: "订阅号",
          url: "https://oss.yanxin7.cn/2023-07-06/20e7ea84f538c399ae3d55b587fafdeb",
        },
      ],
    };
  },
  mounted() {
    this.getUrlBrowseUserStatTheme1();
    this.getUrlVieweUserStatTheme1();
    this.getTheme1();
    this.getTheme2();
  },
  computed: {
    ...mapState("system", ["visitTotal", "registerUserTotal", "todayVisiNum"]),
  },
  components: {},
  methods: {
    async getTheme2() {
      try {
        const { data } = await theme2({});
        this.totalCompany = data.total;
      } catch (error) {
        console.log("入驻企业err", error);
      }
    },
    async getTheme1() {
      try {
        const { data } = await theme1({});
        this.todayTotal = data.todayTotal;
        this.totalView = data.total;
      } catch (error) {
        console.log("err", error);
      }
    },

    handMap() {
      const w = window.open("about:blank");
      w.location.href =
        "https://map.baidu.com/search/%E9%82%A6%E5%BB%BA%E9%80%9A%E7%A7%91%E6%8A%80%E9%9B%86%E5%9B%A2%E8%82%A1%E4%BB%BD%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@12067494.485,2592805.91,19z?querytype=s&da_src=shareurl&wd=%E9%82%A6%E5%BB%BA%E9%80%9A%E7%A7%91%E6%8A%80%E9%9B%86%E5%9B%A2%E8%82%A1%E4%BB%BD%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&c=261&src=0&wd2=%E5%8D%97%E5%AE%81%E5%B8%82%E9%9D%92%E7%A7%80%E5%8C%BA&pn=0&sug=1&l=13&b=(12038829,2584033;12100269,2614017)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=8205d445f8e6591404026f34&device_ratio=1";
    },
    handleTabClick(tab) {
      console.log(tab.name);
    },
    handleSearch() {
      console.log(this.searchValue);
      // Perform search logic here
    },
    // 注册用户数
    async getUrlBrowseUserStatTheme1() {
      try {
        const { data } = await urlBrowseRegUserStatTheme1({});
        // this.userViewTotal = data.total;
        this.$store.commit("system/setRegisterUserTotal", data.total);
      } catch (error) {
        console.log("urlBrowseRegUserStatTheme1", error);
      }
    },
    // 总访问量
    async getUrlVieweUserStatTheme1() {
      try {
        const { data } = await urlBrowseUserStatTheme1({});
        this.$store.commit("system/setVisitTotal", data.total);
      } catch (error) {
        console.log("urlBrowseRegUserStatTheme1", error);
      }
    },
  },
};
</script> 
<style scoped>
a {
  color: #020a1d;
}
.dibu {
  cursor: pointer;
  width: 100%;
  background: #016be6;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.msg {
  font-size: 16px;
  color: #ffffff;
  margin-right: 20px;
  margin-left: 20px;
}

.shuxian {
  width: 3px;
  height: 18px;
  color: #ffffff;
  font-size: 16px;
}
.footer-yinsi {
  width: 100%;
  color: white;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: flex-start;
}

.app-list {
  display: flex;
  width: 100%;
  padding-left: 70px;
}
.app-item {
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-right: 32px;
  margin-bottom: 16px;
}
a {
  text-decoration: none;
  color: white;
}
.footer-box {
  width: 1520px;
  display: flex;
}
.f-left {
  width: 500px;
  border-right: 1px solid white;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
}
.flex-cloumn-start {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
</style>
