import layout from '@src/fristPageLayout'
let pre = 'join-'
export default {
  path: '/join',
  name: 'join',
  redirect: {
    name: `${pre}join`,
  },
  component: layout,
  children: [
    {
      path: 'join',
      name: `${pre}join`,
      meta: {
        title: '首页',
        header: true,
        auth: false,
      },
      component: () => import('@view/join'),
    }
  ],
}
