<template>
  <el-container class="min-w">
    <el-header class="header-area">
      <div class="flex-lr">
        <div class="flex a-mr11">
          <div>
            <el-image style="width: 30px; height: 30px; margin-top: 5px"
              :src="require('../assets/images/bjtsy/logo.png')"></el-image>
          </div>
          <div class="company">
            邦建通数字建筑平台
          </div>
        </div>
        <div class="flex">
          <div class="nav">
            <a target="_blank" :class="routePath == '/dataCenter/dataCenter' && 'is_active'"
              @click="toPath('/dataCenter/dataCenter')">首页</a>
          </div>
          <div class="nav">
            <a target="_blank" @click="toPath('/subwebsite/ecosystem')">生态体系</a>
          </div>
          <div class="nav" @click="toPath('/join/join')">加盟申请</div>
          <div class="nav" @click="toPath('/about/about')">
            <a target="_blank">联系我们</a>
          </div>
          <el-popover placement="bottom" width="120" trigger="hover">
            <el-button size="mini" class="member-btn" slot="reference">
              <div class="">
                <el-image style="width: 78px; height: 24px; margin-top: 5px"
                  src="https://oss.yanxin7.cn/2023-07-03/daa7d55c2a20faf84682882048aaec38"></el-image>
              </div>
            </el-button>
            <div class="member-type">
              <div style="margin-bottom: 1vw" @click="toPath(`/member/member?type=company`)">
                企业会员
              </div>
              <div @click="toPath(`/member/member?type=person`)">个人会员</div>
            </div>
          </el-popover>

          <div v-if="!isLoggedIn">
            <div style="height: 24px">
              <button style="margin-top: 2px; line-height: 24px" class="btn" @click="doLogin">
                登录
              </button>
            </div>
          </div>
          <div v-if="isLoggedIn">
            <div class="back-btn">
              <el-popover placement="bottom" width="150" trigger="click">
                <div class="flex-t txt-fs mb-10">
                  <div class="list-t"></div>
                </div>
                <div class="flex-t txt-fs mb-10" @click="loginOut">
                  <div class="list-t"></div>
                  <div>退出登录</div>
                </div>

                <button class="btn-person" slot="reference">
                  <span>
                    <img class="img1"
                      :src="userInfos.headPortrait || 'https://oss.yanxin7.cn/2023-08-23/7da900523b43328139a18b69b1fba7ee'"
                      alt="" />
                  </span>
                  <span>
                    <img class="img2" :src="require('@/assets/images/bjtsy/m12605.png')" alt="" /></span>
                </button>
              </el-popover>
            </div>
          </div>
          <div class="phone">
            <el-image style="height: 40px; width: 40px"
              src="https://oss.yanxin7.cn/2023-07-05/6159ce9a541555c79a526614fcef60d2"></el-image>
            &nbsp;&nbsp;&nbsp; 0771-4985-999
          </div>
        </div>
      </div>
    </el-header>
    <div id="mainScrollArea" style="padding: 60px 0 0">
      <router-view v-if="isRouterAlive" :key="path"></router-view>
      <servicer></servicer>

      <login-form v-if="showLoginForm" :routeRedirect="routeRedirect" @loginSuccess="handleLoginSuccess"
        @close="handleLoginClose" />
      <Footer style="width: 100%; margin: auto"></Footer>
    </div>
  </el-container>
</template>
<script>
import { mapState } from "vuex";
import Header from "./Header";
import Footer from "./Footer";
import StatisticsNumber from "@/components/statistics-number";
import LoginForm from "@/components/login-form";
import { qrUserInfo } from "@src/api/account.js";
import Utils from "@src/utils/shift.js";

export default {
  components: {
    Header,
    Footer,
    StatisticsNumber,
    LoginForm,
  },

  computed: {
    ...mapState("account", ["token", "userInfos"]),
    isLoggedIn() {
      return this.token && this.userInfos;
    },
    routeRedirect() {
      return this.$route.query.redirect;
    },
  },
  data() {
    return {
      userInfo: {
        name: "aeqw",
      },
      codeurl: "",
      isRouterAlive: true,
      showLoginForm: false,
      path: "",
      activeTab: "home",
      searchValue: "",
      redirectPath: "",
      propsData: [
        { name: "入驻用户量：", value: "123", unit: "人" },
        { name: "今日访问量：", value: "124", unit: "人" },
        { name: "累计访问量：", value: "125", unit: "人" },
      ],
      propsConfig: {
        boxWidth: "80%",
      },
      menuList: [
        { label: "首页", name: "home" },
        { label: "资质查询", name: "qualification" },
        { label: "金融服务", name: "record" },
        { label: "招标投标", name: "personnel" },
        { label: "机械管理", name: "project" },
        { label: "造价咨询", name: "certificate" },
        { label: "智慧工地", name: "creditscore" },
      ],
      search: "",
      isSearch: "",
      routePath: "",
    };
  },
  beforeDestroy() {
    window.removeEventListener("setItem", this.handleSessionStorageChange);
  },
  mounted() {
    window.addEventListener("setItem", this.handleSessionStorageChange);
    let routePath = this.$route.path;
    this.routePath = routePath;
    console.log("routePath=", routePath);
    // if (routePath) {
    //   let paths = routePath.split("/");
    //   if (paths.length > 1 && paths[1]) this.activeTab = paths[1];
    // }
    this.tryQrUserInfo();
  },
  methods: {
    // 登出
    loginOut() {
      this.clearAllCookies();
    },
    // 清除所有Cookies
    clearAllCookies() {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var cookieName = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie =
          cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      }
      this.$message({
        message: "退出成功！",
        type: "success",
      });
      setTimeout(() => {
        location.reload(); // 强制刷新页面
      }, 1500);
    },
    handAbout() {
      window.href("");
    },
    getRouter() {

      this.isSearch = this.$route?.meta?.layout?.isSearch;
      let isTabs = this.$route?.meta?.layout?.isTabs;
      if (isTabs) {
        this.menuList = [
          { label: "首页", name: "recruit" },
          { label: "工程招标", name: "project-bidding" },
          { label: "政府采购", name: "government-procurement" },
          { label: "分包集采", name: "subcontract-acquisition" },
        ];
      } else {
        this.menuList = [
          { label: "首页", name: "home" },
          { label: "资质查询", name: "qualification" },
          { label: "备案查询", name: "record" },
          { label: "人员查询", name: "personnel" },
          { label: "工程项目", name: "project" },
          { label: "电子证照", name: "certificate" },
          { label: "信用分", name: "creditscore" },
        ];
      }
    },
    tryQrUserInfo() {
      var query = this.$route.query;
      console.log("this.$route.query", this.$route.query);
      if (query.code && query.state) {
        this.qrUserInfo(this.$route.query.code, this.$route.query.state);
      }
    },
    scanCodeLogin() {
      let that = this;
      this.$store.commit("account/setToken", this.userInfo.token);
      this.$store.dispatch("account/getNewUserInfo")

      var toPage = that.$route.query.redirect || "/";
      console.log("toPage===>", toPage);
      that.$router.replace(toPage);
    },
    // 获取用户信息
    qrUserInfo(code, state) {
      qrUserInfo({ code, state }).then((res) => {
        this.userInfo = res.data;
        window.history.replaceState(null, null, this.getnewurl())
        this.scanCodeLogin();
      });
    },
    getnewurl() {
      let url = document.location.href
      let reg = /[^\w](code|state)=?([^&|^#]*)/g;
      url = url.replace(reg, "");
      let paramsIndex = url.indexOf("&")
      if (paramsIndex !== -1) {
        url = url.substring(0, paramsIndex) + "?" + url.substring(paramsIndex + 1, url.length)
      }
      return url;
    },
    handleSessionStorageChange() {
      let needLogin = sessionStorage.getItem("needLogin");
      console.log("sessionStorage value changed:", needLogin);
      let nlInfo = JSON.parse(needLogin);
      if (nlInfo && nlInfo.value) {
        this.redirectPath = nlInfo.redirect || "/";
        this.doLogin();
      }
    },
    toPath(url) {
      let routePath = this.$route.path;
      this.routePath = routePath;
      console.log("this.routePath", this.routePath);
      console.log("url", url);
      if (
        url == "/member/member?type=company" &&
        url.includes(this.routePath)
      ) {
        Utils.$emit("changMemberType", "company");
        return;
      }
      if (url == "/member/member?type=person" && url.includes(this.routePath)) {
        Utils.$emit("changMemberType", "person");
        return;
      }
      if (url == "/websiteIndex/websiteIndex") {
        this.$router.push(url);
        return;
      }
      let theUrlPrefix = "https://api.yanxin7.cn";
      if (window.location.origin.includes("trial.yanxin7.cn")) {
        theUrlPrefix = "https://trial.yanxin7.cn";
      } else if (
        window.location.origin.includes("dev.yanxin7.cn") ||
        window.location.origin.includes("localhost")
      ) {
        theUrlPrefix = "https://dev.yanxin7.cn";
      }
      if (url == "/bxbh/#/login") {
        const w = window.open("about:blank");
        w.location.href = theUrlPrefix + `/bxbh/#/insurance/home/index`;
        return;
      }

      if (url.includes(this.routePath)) {
        location.reload();
        return;
      }
      if (url == "/about/about") {
        let vRouter = this.$router.resolve({
          path: url,
          params: {},
        });
        window.open(vRouter.href, "_blank");
        return;
      }
      if (url.includes("/member/member")) {
        let vRouter = this.$router.resolve({
          path: url,
          params: {},
        });
        window.open(vRouter.href, "_blank");
        return;
      }

      let token = this.$store.state.account.token;
      if (
        !token &&
        url !== "/join/join" &&
        url !== "/websiteIndex/websiteIndex"
      ) {
        this.showLoginForm = true;
        return;
      }
      let vRouter = this.$router.resolve({
        path: url,
        params: {},
      });
      window.open(vRouter.href, "_blank");
    },
    handleLoginSuccess(toPage) {
      let redirectPath = this.redirectPath || toPage;
      this.resetSetItem("needLogin", JSON.stringify({ value: false }));
      this.showLoginForm = false;
      // this.$router.replace(toPage);
      console.log("handleLoginSuccess-->toPage=" + redirectPath);
      this.redirectPath = "/";
      this.$router.push({ path: redirectPath });
    },
    handleLoginClose() {
      this.showLoginForm = false;
      // this.activeTab = "websiteIndex";
      // if (this.redirectPath != "/") {
      //   this.redirectPath = "/websiteIndex";
      //   this.$router.push({ path: this.redirectPath });
      // }
    },
    doLogin() {
      // this.$router.push({ path: "/login" });
      this.showLoginForm = true;
    },
    onSearch() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
        this.$router.push({
          path: this.$route.path,
          query: { search: this.search },
        });
      });
    },
    doSearch(searchView, searchValue) {
      let oldActiveTab = this.activeTab;
      let oldSearchValue = this.searchValue;
      if (searchView == oldActiveTab && searchValue == oldSearchValue) {
        this.isRouterAlive = false;
        this.$nextTick(() => {
          this.isRouterAlive = true;
          this.routerPush(this.activeTab, searchValue);
        });
      } else {
        this.activeTab = searchView;
        this.searchValue = searchValue;
        this.routerPush(this.activeTab, searchValue);
      }
    },
    handleTabClick(tab) {
      console.log(tab.name);
      // this.routerPush(tab.name);
    },
    routerPush(pathName, searchValue) {
      let param = { path: "/" + pathName + "/index" };
      if (searchValue) {
        param.query = { name: searchValue };
      }
      console.log("routerPush.param", param);
      this.$router.push(param);
    },
    scrollToTop() {
      const mainElement = document.getElementById("mainScrollArea");
      mainElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style></style>
<style scoped lang="less">
.header-area {
  min-width: 1520px;
  height: 60px !important;
  background: #ffffff !important;
  box-shadow: 0px 3px 6px 1px rgba(3, 96, 230, 0.06);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
}

.company {
  cursor: pointer;
  font-size: 1.5rem;
  font-family: Source Han Sans CN-Regular;
  font-weight: bold;
  color: #007ef2;
  /* margin-right: 302px; */
  margin-left: 10px;
}

.nav {
  font-size: 20px;
  font-family: Source Han Sans CN-Regular;
  color: #212121;
  cursor: pointer;
  margin-right: 2vw;
}

.btn {
  width: 60px;
  height: 24px;
  font-size: 14px;
  background-color: #007ef2;
  border-radius: 4px 4px;
  color: #ffffff;
  text-align: center;
  line-height: 26px;
  border: none;
  cursor: pointer;
}

.flex {
  display: flex;
  align-self: start;
  align-items: center;
  justify-content: center;
}

/* 靠左-上 */
.flex-t {
  display: flex;
  align-self: start;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  cursor: pointer;
}

/* 靠左-右 */
.flex-lr {
  width: 1520px;
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-self: start;
  align-items: center;
  justify-content: space-between;
}

.back-btn {
  margin-left: 44px;
}

.img1 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.img2 {
  margin-left: 12px;
  padding-bottom: 8px;
}

.txt-fs {
  font-size: 18px;
  padding-left: 10px;
}

.img-t1 {
  width: 18px;
  height: 18px;
}

.list-t {
  padding-top: 3px;
  margin-right: 5px;
}

.btn-person {
  background-color: #ffffff;
  width: 70px;
  padding-top: 3px;
  border: none;
  cursor: pointer;
}

.member-btn {
  overflow: hidden;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  margin-right: 30px;
}

.member-type {
  width: 5vw;
  margin-left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-weight: bolder;
  cursor: pointer;
}

.mb-10 {
  margin-bottom: 10px;
}

/* // */
::v-deep .el-tabs--border-card>.el-tabs__content {
  padding: 0px;
}

.min-w {
  min-width: 1450px;
}

::v-deep .el-header {
  padding: 10px 0px 0px 0px;
}

/* 菜单箭头 */
::v-deep .el-tabs__nav-next,
::v-deep .el-tabs__nav-prev {
  position: absolute;
  cursor: pointer;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  color: #ffffff;
}

::v-deep .el-tabs__nav-next.is-disabled,
::v-deep .el-tabs__nav-prev.is-disabled {
  color: #ffffff;
}

::v-deep .el-tabs--border-card>.el-tabs__header {
  /* background-color: #F5F7FA; */
  /* border-bottom: 1px solid #E4E7ED; */
  margin: 0;
}

::v-deep .el-tabs--bottom .el-tabs--left>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom .el-tabs--right>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom.el-tabs--border-card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--left>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--right>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top.el-tabs--border-card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2) {
  padding-left: 0px;
}

.page-view {
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}

::v-deep .el-tabs--border-card>.el-tabs__header {
  border-bottom: none;
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  margin-top: 23px;
}

.info h1 {
  margin-bottom: 20px;
  font-weight: bolder;
}

.info-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info-left {
  margin-right: 40px;
}

.info-left_video {
  width: 600px;
  height: 338px;
  opacity: 1;
}

.info-left_list {
  width: 600px;
  height: 338px;
  opacity: 1;
}

.list-icon {
  width: 7px;
  height: 7px;
  background: #2288fc;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-right: 24px;
}

.list-item_text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 416px;
}

.info-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 39px;
  cursor: pointer;
}

.info-list-item:hover {
  color: #2288fc;
}

.service-box {
  display: flex;
  width: 1200px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.service-item {
  width: 226px;
  height: 96px;
  background: #ffffff;
  box-shadow: 3px 3px 16px 1px rgba(61, 145, 255, 0.16);
  opacity: 1;
  border: 1px solid #e2e5ec;
  margin-bottom: 18px;
  padding: 16px 13px;
}

.service-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.service-text {
  width: 80px;
  font-size: 20px;
  font-weight: 400;
}

.service-item:hover {
  cursor: pointer;

  .service-text {
    color: #2288fc;
  }
}

.parent-item {
  width: 180px;
  margin-bottom: 18px;
}

a {
  text-decoration: none;
  color: black;
}

.phone {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.is_active {
  color: #2288fc;
}
</style>
