<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    const screenWidth = window.screen.width;
    if (screenWidth < 1520) {
      let zoom = ((screenWidth / 1520)*100).toFixed(1) + '%'
      console.log('----设置缩放比例', zoom)
      document.body.style.zoom = zoom
      // document.body.style.zoom = '70%'
    }
  },
  mounted() {
    this.$store.dispatch('address/storeAddress')
  }
}
</script>

<style scoped>
    @import './assets/css/base.css';
#app {
  font-family: Source Han Sans CN-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
}

</style>
