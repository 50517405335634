import layout from "@src/layout"
let pre = "personnel-"
export default {
    path: '/personnel',
    name: 'personnel',
    redirect: {
        name: `${pre}personnel`
    },
    component: layout,
    children: [{
        path: 'index',
        name: `${pre}index`,
        meta: {
            title: "人员查询",
            header: true,
            auth: true,
        },
        component: () =>
            import ("@view/personnel")
    }, {
        path: 'detail',
        name: `${pre}/detail`,
        meta: {
            title: '人员详情',
            header: true,
            auth: true
        },
        component: () => import('@view/personnel/detail'),
    }]
}