<template>
  <div class="vice">
    <div
      class="flex_row_center vice_box"
      :style="{ width: propsConfig.boxWidth }"
    >
      <div class="flex vice_left">
        <div
          class="flex_center vice_item"
          v-for="(item, index) in propsData"
          :key="index"
        >
          <div class="flex_center vice_num">
            {{ item.name }}
            <span>{{ item.value }}</span>
            {{ item.unit }}
          </div>
        </div>
      </div>
      <!-- <div class="vice_right">
        <div class="vice_right_vip" @click="onFun">
          <img src="@/assets/images/bjtsy/z20052.png" alt="" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "statistics-number",
  props: {
    propsData: {
      type: Array,
      default: () => [],
    },
    propsConfig: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      deadline: 132456,
    };
  },
  mounted() {},
  methods: {
    onFun() {},
  },
};
</script>

<style scoped lang="less">
.vice {
  background-color: #fff;
  box-shadow: 0 3px 6px 1px rgba(3, 96, 230, 0.06);
  .vice_box {
    // width: 1200px;
    // min-width: 1200px;
    margin-left: 10%;
    margin-right: 10%;
    height: 60px;
    margin: 0 auto;
    justify-content: space-between;
    .vice_left {
      .vice_item {
        border-right: 2px solid #4e4e4e;
        padding-right: 20px;
        margin-right: 20px;
        .vice_num {
          font-size: 14px;
          font-weight: 500;
          color: #4e4e4e;
          > span {
            font-size: 18px;
            color: #007ef2;
            font-weight: bold;
          }
        }
      }
      :last-child {
        border: none;
        padding: 0;
        margin: 0;
      }
    }
    .vice_right {
      .vice_right_vip {
        width: 120px;
        height: 40px;
        > img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>