import Vue from 'vue'
import App from './App.vue'
// vueRoute
import router from './router'
// veux
import store from './store'
//Axios
import axios from 'axios'
import http from '@/utils/request'
// JSON����չʾ
import jsonView from 'vue-json-viewer'
import lodash from 'lodash';
// ���
import mixinApp from '@src/mixins/app';
// ������
import filters from '@src/filters/index.js'
// md5����
import md5 from 'md5';
import scroll from 'vue-seamless-scroll'

// �Զ���ȫ�����
import bjtIcon from '@com/bjt-icon'
import servicer from '@com/servicer'

// ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.prototype.$http = http
Vue.config.productionTip = false
Vue.prototype.$md5 = md5
Vue.prototype.$axios = axios
Vue.prototype.$lodash = lodash
Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'needLogin') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);

        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}
Vue.use(ElementUI)
Vue.use(scroll)
Vue.component("bjtIcon",bjtIcon)
Vue.component("servicer",servicer)

new Vue({
  router,
  store,
  mixins: [mixinApp],
  render: h => h(App),
}).$mount('#app')
