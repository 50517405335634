import container from "@src/layout/container"
import { meta } from "@src/layout/container/metaLayout"
let pre = "management-"
export default [
  {
    path: '/management',
    name: 'management',
    redirect: {
      name: `${pre}management`
    },
    component: container,
    children: [{
      path: 'index',
      name: `${pre}index`,
      meta: {
        title: "管理体系",
        header: true,
        auth: true,
        layout: meta({ activeTab: 'management' }).management,
      },
      component: () =>
        import ("@view/management")
    }]
  },
]