import layout from '@src/fristPageLayout'
let pre = 'about-'
export default {
  path: '/about',
  name: 'about',
  redirect: {
    name: `${pre}about`,
  },
  component: layout,
  children: [
    {
      path: 'about',
      name: `${pre}about`,
      meta: {
        title: '首页',
        header: true,
        auth: false,
      },
      component: () => import('@view/about'),
    },
 
  ],
}
