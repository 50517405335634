<template>
  <el-container class="min-w">
    <!-- <topHeader @handLogin="doLogin"></topHeader> -->
    <servicer></servicer>
    <div id="mainScrollArea" style="width: 100%; padding: 0px 0 0">
      <router-view v-if="isRouterAlive" :key="path"></router-view>
      <login-form v-if="showLoginForm" :routeRedirect="routeRedirect" @loginSuccess="handleLoginSuccess"
        @close="handleLoginClose" />

      <div style="min-width: 1520px;">
        <Footer></Footer>
      </div>
    </div>
  </el-container>
</template>
<script>
import { mapState } from "vuex";
import topHeader from "../components/topHeader";
import Footer from "../components/footer";
import StatisticsNumber from "@/components/statistics-number";
import LoginForm from "@/components/login-form";
import { qrUserInfo } from "@src/api/account.js";
import Utils from "@src/utils/shift.js";

export default {
  components: {
    topHeader,
    Footer,
    StatisticsNumber,
    LoginForm,
  },

  computed: {
    ...mapState("account", ["token", "userInfos"]),
    isLoggedIn() {
      return this.token && this.userInfos;
    },
    routeRedirect() {
      return this.$route.query.redirect;
    },
  },

  data() {
    return {
      userInfo: {},
      codeurl: "",
      isRouterAlive: true,
      showLoginForm: false,
      path: "",
      activeTab: "home",
      redirectPath: "",
      routePath: "",
    };
  },

  mounted() {
    window.addEventListener("setItem", this.handleSessionStorageChange);
    let routePath = this.$route.path;
    this.routePath = routePath;
    this.tryQrUserInfo();
  },

  beforeDestroy() {
    window.removeEventListener("setItem", this.handleSessionStorageChange);
  },


  methods: {
    tryQrUserInfo() {
      var query = this.$route.query;
      if (query.code && query.state) {
        this.qrUserInfo();
      }
    },
    scanCodeLogin() {
      // 扫码登录成功调用-----
      console.log("扫码登录成功调用")
      let that = this;
      this.$store.commit("account/setToken", this.userInfo.token);
      this.$store.dispatch("account/getNewUserInfo")
      var toPage = that.routeRedirect || "/";
      that.$emit("loginSuccess", toPage);
      Utils.$emit("loginSuccess")
    },

    // 获取用户信息
    qrUserInfo() {
      let { code, state } = this.$route.query
      let params = {}
      if (Array.isArray(code) && Array.isArray(state)) {
        params.code = code.splice(-1)[0] || ""
        params.state = state.splice(-1)[0] || ""
      } else {
        params.code = code
        params.state = state
      }
      qrUserInfo(params).then((res) => {
        window.history.replaceState(null, null, this.getnewurl())
        this.userInfo = res.data;
        this.scanCodeLogin();
      }).catch(err => {
        this.$router.replace("/")
      });
    },
    getnewurl() {
      let url = document.location.href
      let reg = /[^\w](code|state)=?([^&|^#]*)/g;
      url = url.replace(reg, "");
      let paramsIndex = url.indexOf("&")
      if (paramsIndex !== -1) {
        url = url.substring(0, paramsIndex) + "?" + url.substring(paramsIndex + 1, url.length)
      }
      return url;
    },
    handleSessionStorageChange() {
      let needLogin = sessionStorage.getItem("needLogin");
      let nlInfo = JSON.parse(needLogin);
      if (nlInfo && nlInfo.value) {
        this.redirectPath = nlInfo.redirect || "/";
        this.doLogin();
      }
    },
    toPath(url) {
      let routePath = this.$route.path;
      this.routePath = routePath;
      if (
        url == "/member/member?type=company" &&
        url.includes(this.routePath)
      ) {
        Utils.$emit("changMemberType", "company");
        return;
      }
      if (url == "/member/member?type=person" && url.includes(this.routePath)) {
        Utils.$emit("changMemberType", "person");
        return;
      }
      if (url == "/websiteIndex/websiteIndex") {
        let vRouter = this.$router.resolve({
          path: url,
          params: {},
        });
        window.open(vRouter.href, "_blank");
        return;
      }
      let theUrlPrefix = "https://api.yanxin7.cn";
      if (window.location.origin.includes("trial.yanxin7.cn")) {
        theUrlPrefix = "https://trial.yanxin7.cn";
      } else if (
        window.location.origin.includes("dev.yanxin7.cn") ||
        window.location.origin.includes("localhost")
      ) {
        theUrlPrefix = "https://dev.yanxin7.cn";
      }
      if (url == "/bxbh/#/login") {
        const w = window.open("about:blank");
        w.location.href = theUrlPrefix + `/bxbh/#/insurance/home/index`;
        return;
      }

      if (url.includes(this.routePath)) {
        location.reload();
        return;
      }
      if (url == "/about/about") {
        let vRouter = this.$router.resolve({
          path: url,
          params: {},
        });
        window.open(vRouter.href, "_blank");
        return;
      }
      if (url.includes("/member/member")) {
        let vRouter = this.$router.resolve({
          path: url,
          params: {},
        });
        window.open(vRouter.href, "_blank");
        return;
      }

      let token = this.$store.state.account.token;
      if (
        !token &&
        url !== "/join/join" &&
        url !== "/websiteIndex/websiteIndex"
      ) {
        this.showLoginForm = true;
        return;
      }
      let vRouter = this.$router.resolve({
        path: url,
        params: {},
      });
      window.open(vRouter.href, "_blank");
    },
    handleLoginSuccess(toPage) {
      let redirectPath = this.redirectPath || toPage;
      this.resetSetItem("needLogin", JSON.stringify({ value: false }));
      this.showLoginForm = false;
      this.redirectPath = "/";
      this.$router.push({ path: redirectPath });
    },
    handleLoginClose() {
      this.showLoginForm = false;
    },
    doLogin() {
      this.showLoginForm = true;
    },

    scrollToTop() {
      const mainElement = document.getElementById("mainScrollArea");
      mainElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style scoped>
.min-w {
  width: 100%;
}
</style>

