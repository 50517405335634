import layout from '@src/layout'
let pre = 'home-'
export default {
  path: '/home',
  name: 'home',
  redirect: {
    name: `${pre}home`,
  },
  component: layout,
  children: [
    {
      path: 'index',
      name: `${pre}index`,
      meta: {
        title: '首页',
        header: true,
        auth: false,
      },
      component: () => import('@view/home'),
    },
    {
      path: 'detail',
      name: `${pre}/detail`,
      meta: {
        title: '中标企业',
        header: true,
        auth: true,
      },
      component: () => import('@view/home/detail'),
    },
    // detailManage
    {
        path: 'detailManage',
        name: `${pre}/detailManage`,
        meta: {
          title: '中标项目经理',
          header: true,
          auth: true,
        },
        component: () => import('@view/home/detailManage'),
      },
  ],
}
