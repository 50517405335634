// 错误页面
const errorPage = [{
        path: "/403",
        name: "403",
        meta: {
            title: "403",
        },
        component: () =>
            import ("@view/status/error/403"),
    },
    {
        path: "/500",
        name: "500",
        meta: {
            title: "500",
        },
        component: () =>
            import ("@view/status/error/500"),
    },
    {
        path: "*",
        component: () =>
            import ("@view/status/error/404"),
    },
];
// 在框架外的路由
const frameOut = [
    {
        path: "/login",
        name: "login",
        redirect: "/websiteIndex/index"
        // meta: {
        //     title: "登录",
        // },
        // component: () =>
        //     import ("@view/account/login"),
    },
];

export default [...frameOut, ...errorPage];
