import Vue from 'vue'
import store from '@src/store'
import VueRouter from 'vue-router'
import globalRouters from './globalRouters'
import moduleRouters from './moduleRouters'
import Setting from '@src/setting.js'
import { getUserInfoByToken } from "@src/api/account.js";

Vue.use(VueRouter)
    // 解决vue-router在3.0版本以上重复报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    routes: [...globalRouters, ...moduleRouters],
    mode: Setting.routerMode,
    base: Setting.routerBase
});
// 路由跳转之前
router.beforeEach((to, form, next) => {
    // 判断是否需要登录
    if (to.meta && to.meta.auth) {
        let token = store.state.account.token;
        let userInfos = store.state.account.userInfos;
        console.log('ssssd',token)
        let path = to.path;
        // 判断是否存在token
    if (token) {
        if (!userInfos) {
            getUserInfoByToken({token: token}).then((res) => {
                console.log('getUserInfoByToken success.');
                store.commit("account/setUserInfos", res.data);
                Vue.prototype.resetSetItem("needLogin", JSON.stringify({ value: false }));
                next()
            })
            .catch((err) => {
                // 出错则设置需要登录
                console.log("err", err);
                Vue.prototype.resetSetItem("needLogin", JSON.stringify({ value: true, redirect: to.fullPath }));
            })
        } else {
            Vue.prototype.resetSetItem("needLogin", JSON.stringify({ value: false }));
                next()
        }

    } else {
      // 不存在则设置需要登录
      Vue.prototype.resetSetItem("needLogin", JSON.stringify({ value: true, redirect: to.fullPath }));
      next({path:"/"})
    }
  } else {
    Vue.prototype.resetSetItem("needLogin", JSON.stringify({ value: false }));
        next()
    }
});
export default router
