import layout from '@src/fristPageLayout'
let pre = 'websiteIndex-'
export default {
  path: '/websiteIndex',
  name: 'websiteIndex',
  redirect: {
    name: `${pre}websiteIndex`,
  },
  component: layout,
  children: [
    {
      path: 'websiteIndex',
      name: `${pre}websiteIndex`,
      meta: {
        title: '首页',
        header: true,
        auth: false,
      },
      component: () => import('@view/websiteIndex'),
    },
 
  ],
}
