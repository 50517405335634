import container from "@src/layout/container"
import { meta } from '@src/layout/container/metaLayout'
let pre = 'engineering-'
export default [
  {
    path: '/engineering',
    name: 'engineering',
    redirect: {
      name: `${pre}engineering`,
    },
    component: container,
    children: [
      {
        path: 'index',
        name: `${pre}index`,
        meta: {
          title: '首页',
          header: true,
          auth: true,
          layout: meta({ activeTab: 'engineering' }).engineering,
        },
        component: () => import('@view/engineering'),
      },
      {
        path: 'quota-list',
        name: `${pre}quota-list`,
        meta: {
          title: '造价定额列表',
          header: true,
          auth: true,
          layout: meta().engineeringDetails,
        },
        component: () => import('@view/engineering/quotaList'),
      },
      {
        path: 'quota-details',
        name: `${pre}quota-details`,
        meta: {
          title: '造价定额详情',
          header: true,
          auth: true,
          layout: meta().engineeringDetails,
        },
        component: () => import('@view/engineering/quotaList/details'),
      },
      {
        path: 'details',
        name: `${pre}details`,
        meta: {
          title: '造价定额详情',
          header: true,
          auth: true,
          layout: meta().engineeringDetails,
        },
        component: () => import('@view/engineering/quotaDetails'),
      },
      {
        path: 'costSoftware',
        name: `${pre}costSoftware`,
        meta: {
          title: '造价软件',
          header: true,
          auth: true,
          layout: meta({ activeTab: 'engineering' }).engineering,
        },
        component: () => import('@view/engineering/costSoftware/index'),
      },
      {
        path: 'tendency-chart',
        name: `${pre}tendency-chart`,
        meta: {
          title: '造价趋势',
          header: true,
          auth: true,
          layout: meta({ activeTab: 'engineering' }).engineeringDetails,
        },
        component: () => import('@view/engineering/tendencyChart/index'),
      },
      {
        path: 'tendency-chart-details',
        name: `${pre}tendency-chart-details`,
        meta: {
          title: '人工成本详情',
          header: true,
          auth: true,
          layout: meta({ activeTab: 'engineering' }).engineeringDetails,
        },
        component: () => import('@view/engineering/tendencyChart/details'),
      },
    ],
  },
  {
    path: '/info-price',
    name: 'info-price',
    redirect: {
      name: `${pre}info-price`,
    },
    component: container,
    children: [
      {
        path: 'index',
        name: `${pre}info-price-index`,
        meta: {
          title: '信息价',
          header: true,
          auth: true,
          layout: meta({ activeTab: 'engineering' }).engineering,
        },
        component: () => import('@view/engineering/infoPrice'),
      },
      {
        path: 'periodical',
        name: `${pre}info-price-periodical`,
        meta: {
          title: '期刊详情',
          header: true,
          auth: true,
          layout: meta({ activeTab: 'engineering' }).engineering,
        },
        component: () => import('@view/engineering/infoPrice/periodical'),
      },
      {
        path: 'details',
        name: `${pre}info-price-details`,
        meta: {
          title: '详情',
          header: true,
          auth: true,
          layout: meta({ activeTab: 'engineering' }).engineering,
        },
        component: () => import('@view/engineering/infoPrice/details'),
      },
    ],
  },
  {
    path: '/reference-price',
    name: 'reference-price',
    redirect: {
      name: `${pre}reference-price`,
    },
    component: container,
    children: [
      {
        path: 'index',
        name: `${pre}reference-price-index`,
        meta: {
          title: '参考价',
          header: true,
          auth: true,
          layout: meta({ activeTab: 'engineering' }).engineering,
        },
        component: () => import('@view/engineering/referencePrice'),
      },
    ],
  },
  {
    path: '/creative-personnel',
    name: 'creative-personnel',
    redirect: {
      name: `${pre}creative-personnel`,
    },
    component: container,
    children: [
      {
        path: 'index',
        name: `${pre}creative-personnel-index`,
        meta: {
          title: '造价人员',
          header: true,
          auth: true,
          layout: meta({ activeTab: 'engineering' }).engineering,
        },
        component: () => import('@view/engineering/creativePersonnel'),
      },
      {
        path: 'detail',
        name: `${pre}detail`,
        meta: {
          title: '预算人员详情',
          header: true,
          auth: true,
          layout: meta({ activeTab: 'engineering' }).engineering,
        },
        component: () => import('@view/engineering/creativePersonnel/detail'),
      },
      {
        path: 'detailBusiness',
        name: `${pre}detailBusiness`,
        meta: {
          title: '预算业务详情',
          header: true,
          auth: true,
          layout: meta({ activeTab: 'engineering' }).engineering,
        },
        component: () => import('@view/engineering/creativePersonnel/detailBusiness'),
      },
    ],
  },
  {
    path: '/creative-company',
    name: 'creative-company',
    redirect: {
      name: `${pre}creative-company`,
    },
    component: container,
    children: [
      {
        path: 'index',
        name: `${pre}creative-company-index`,
        meta: {
          title: '造价公司',
          header: true,
          auth: true,
          layout: meta({ activeTab: 'engineering' }).engineering,
        },
        component: () => import('@view/engineering/creativeCompany'),
      },
      {
        path: 'detail',
        name: `${pre}/detail`,
        meta: {
          title: '公司详情',
          header: true,
          auth: true,
          layout: meta({ activeTab: 'engineering' }).engineering,
        },
        component: () => import('@view/engineering/creativeCompany/detail'),
      },
    ],
  },
]
