import request from "@src/plugins/request";
import { dataTool } from "echarts";

// 修改绑定手机号
export const updateUserPhone = function (data) {
    return request({
        url: "/v2/user/binding/phone",
        method: "POST",
        data,
    });
};

// 绑定邮箱
export const updateUserEmail = function (data) {
    return request({
        url: "/bxbh/sys/user/binding/email",
        method: "POST",
        data,
    });
};

// 企业列表
export const getMyEnterpriseList = function (data) {
    return request({
        url: "/bxbh/enterprise/mySettledEnterpriseList",
        method: "GET",
        params: data,
    });
};

// 更新或设置密码
export const updatePassword = function (data) {
    return request({
        url: "/v2/user/password",
        method: "POST",
        data,
    });
};

// 更新或设置密码
export const resetPassword = function (data) {
    return request({
        url: "/v2/user/forget/password",
        method: "POST",
        data,
    });
};

// 编辑个人信息
export const updateUserInfo = function (data) {
    return request({
        url: "/v2/user/info/update",
        method: "POST",
        data,
    });
};

// 获取消息列表
export const getMsgNoticePage = function (data) {
    return request({
        url: "/sys/message/get/unread/page",
        method: "POST",
        data,
    });
};

// 获取消息未读数量
export const getMsgUnread = function (data) {
    return request({
        url: "/sys/message/get/unread/count",
        method: "GET",
        params: data,
    });
};
// 一键已读消息

export const clearUnread = function (data) {
    return request({
        url: "/sys/message/all/read",
        method: "GET",
        params: data,
    });
};
