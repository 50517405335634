import layout from "@src/fristPageLayout";
let pre = "personalCenter-";
export default {
    path: "/enterCompany",
    name: "enterCompany",
    redirect: {
        name: `${pre}enterCompany`,
    },
    component: layout,
    children: [
        {
            path: "enterCompany",
            name: `${pre}enterCompany`,
            meta: {
                title: "企业认证",
                header: true,
                auth: false,
            },
            component: () => import("@view/enterCompany"),
        },
    ],
};
