<template>
  <div>
    <div class="main_bg" :style="styleData && styleData.bgStyle">
      <div class="main_bg_mask" :style="styleData && styleData.bgMaskStyle">
        <div class="flex_column_center main_content" :style="styleData && styleData.mainContentStyle">
          <!--标题-->
          <div class="main_title">
            <h1 :style="styleData.titleStyle" v-if="configData && configData.title">{{configData.title}}</h1>
            <!-- <h3 :style="styleData.viceStyle" v-if="configData && configData.vice">{{configData.vice}}</h3> -->
          </div>
          <!--搜索框-->
          <div class="flex_row_center" v-if="configData && configData.search && configData.search.show">
            <div class="main_search">
              <el-input class="main_search_input" v-model="searchValue" :placeholder="configData.search.placeholder" @onSearch="onSearch"></el-input>
              <el-button type="primary" class="main_search_btn" @click="onSearch">{{configData.search.btn}}</el-button>
            </div>
          </div>
          <!--菜单栏-->
          <div class="flex_row_center main_menu" v-if="configData && configData.menu && configData.menu.show">
            <div class="flex_center main_menu_box">
              <div class="flex_center main_menu_address" v-if="configData && configData.menu && configData.menu.address">
                <el-popover
                  placement="bottom-start"
                  width="auto"
                  trigger="click"
                  v-model="searchShow"
                >
                  <div>
                    <el-cascader-panel :show-all-levels="false" :options="options" @change="getCheckedNodes"></el-cascader-panel>
                  </div>
                  <div class="flex_center main_menu_address_switch" slot="reference">
                    <i class="el-icon-location"></i>
                    <span>地址切换</span>
                    <p class="flex_row_center main_menu_address_switch_text">
                      <span>{{selectValue}}</span>
                      <i :class="['el-icon-arrow-down', searchShow ? 'rotation_180' : 'rotation_180_off']"></i>
                    </p>
                  </div>
                </el-popover>
              </div>
              <el-tabs type="border-card" v-model="configData.menu.activeTab" @tab-click="handleTabClick">
                <el-tab-pane :label="item.label" :name="item.name" v-for="item in configData.menu.list" :key="item.name"></el-tab-pane>
              </el-tabs>
            </div>
          </div>
          <!--菜单栏-->
          <div class="main_search_scheme" v-if="configData && configData.searchScheme && configData.searchScheme.show">
            <div class="flex_row_center main_search_scheme_input">
              <div class="flex_center main_search_scheme_input_l">
                <el-popover
                  placement="bottom-start"
                  width="auto"
                  trigger="click"
                  v-model="searchShow"
                >
                  <div>
                    <el-cascader-panel :show-all-levels="false" :options="options" @change="getCheckedNodes"></el-cascader-panel>
                  </div>
                  <div class="flex_center main_search_scheme_input_l_select" slot="reference">
                    <span>切换</span>
                    <p class="main_search_scheme_input_l_select_icon">
                      <span>{{selectValue}}</span>
                      <i :class="['el-icon-arrow-down', searchShow ? 'rotation_180' : 'rotation_180_off']"></i>
                    </p>
                  </div>
                </el-popover>
              </div>
              <div class="main_search_scheme_input_c">
                <ul class="flex_center main_search_scheme_ul">
                  <li class="main_search_scheme_ul_li" v-for="(item, index) in assetsMenu" :key="item.value">{{item.name}}</li>
                </ul>
                <el-input placeholder="输入您想要的内容（建筑产业AI大模型）" v-model="input" clearable></el-input>
              </div>
              <div class="flex_center main_search_scheme_input_r">
                邦一下
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vice" v-if="configData && configData.statistics && configData.statistics.show">
      <div class="flex_row_center vice_box">
        <div class="flex vice_left">
          <visitData></visitData>
          <!-- <div class="flex_center vice_item" v-for="(item, index) in configData.statistics.list" :key="index">
            <div class="flex_center vice_num">
              {{ item.name }}
              <span>{{ item.value }}</span>
              {{ item.unit }}
            </div>
          </div> -->
        </div>
        <div class="vice_right">
          <!--<div class="vice_right_vip" @click="onFun">
            <img src="@/assets/images/bjtsy/z20052.png" alt="" />
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import visitData from "@/components/visit-data/visit-data.vue";
  import {mapState} from "vuex";

  export default {
    name: "main-header",
    data() {
      return {
        searchValue: '',
        options: [],
        assetsMenu: [
          {name: '专业聚焦', value: 0},
          {name: '精准高效', value: 1},
          {name: '金融赋能', value: 2},
          {name: '智能协同', value: 3},
          {name: '动态可视', value: 4},
          {name: '节约成本', value: 5},
          {name: '增加效益', value: 6},
        ],
        input: '',
        searchShow: false,
        selectValue: '全国'
      }
    },
    components:{
      visitData
    },
    props: {
      configData: {
        type: Object,
        default: () => ({})
      },
      styleData: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      ...mapState({
        twoAddress: state => state.address.twoAddress
      })
    },
    watch: {
      twoAddress: {
        handler(e) {
          this.options = e;
        },
        immediate:  true,
        deep: true
      }
    },
    mounted() {},
    methods: {
      getCheckedNodes(e) {
        this.twoAddress.forEach(item => {
          if (item.value === e[0]) {
            item.children.forEach(child => {
              if (child.value === e[1]) {
                if (child.label === '直辖市') {
                  this.selectValue = item.label
                  this.$store.commit("address/PUBLICADDRESS", item.label)
                  return
                }
                this.selectValue = child.label
                this.$store.commit("address/PUBLICADDRESS", child.label)
              }
            })
          }
        })
        this.searchShow = false;
      },
      handleTabClick(val) {
        this.$emit('handleTabClick', { value: val.name },val.label)
      },
      onSearch() {
        this.$emit('onSearch', { value: this.searchValue })
      }
    }
  }
</script>

<style scoped lang="less">
  * {
    box-sizing: border-box;
  }
  .main_bg {
    background-repeat: no-repeat;
    background-size: cover;
    height: 260px;
    min-width: 1520px;
    .main_bg_mask {
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      .main_content {
        position: relative;
        height: 100%;
        margin: 0 auto;
        .main_title {
          color: #fff;
          line-height: 1;
          > h1 {
            font-size: 30px;
            font-weight: bold;
            letter-spacing: 2px;
            padding-bottom: 25px;
          }
          > h3 {
            position: relative;
            display: inline-block;
            margin-bottom: 30px;
            font-size: 24px;
          }
          > h3::before {
            content: "";
            display: block;
            position: absolute;
            left: -140px;
            top: 50%;
            transform: translateY(-50%);
            width: 100px;
            height: 3px;
            background: linear-gradient(270deg, #FFFFFF 0%, #1890FF 100%);
          }
          > h3::after {
            content: "";
            display: block;
            position: absolute;
            right: -140px;
            top: 50%;
            transform: translateY(-50%);
            width: 100px;
            height: 3px;
            background: linear-gradient(90deg, #FFFFFF 0%, #1890FF 100%);
          }
        }
        .main_search {
          position: relative;
          width: 900px;
          height: 100%;
          .main_search_input {
            /deep/ input {
              height: 50px;
              font-size: 18px;
            }
          }
          .main_search_btn {
            position: absolute;
            right: 4px;
            bottom: 4px;
            top: 4px;
            width: 112px;
            padding: 0;
            font-size: 18px;
          }
        }
        .main_menu {
          height: 40px;
          width: 100%;
          margin: 0 auto;
          background-color: #013c8233;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          .main_menu_box {
            width: 1520px;
            .main_menu_address {
              .main_menu_address_switch {
                height: 40px;
                font-size: 14px;
                color: #fff;
                cursor: pointer;
                > span {
                  padding: 0 4px;
                }
                .main_menu_address_switch_text {
                  width: 120px;
                  height: 39px;
                }
              }
            }
          }
        }
        .main_search_scheme {
          position: absolute;
          bottom: 20px;
          right: 0;
          left: 0;
          color: #fff;
          .main_search_scheme_input {
            align-items: flex-end;
            .main_search_scheme_input_l {
              height: 48px;
              font-size: 20px;
              .main_search_scheme_input_l_select {
                cursor: pointer;
                .main_search_scheme_input_l_select_icon {
                  padding: 0 16px;
                }
              }
            }
            .main_search_scheme_input_c {
              width: 900px;
              /deep/ input {
                height: 48px;
                font-size: 18px;
              }
              .main_search_scheme_ul {
                padding: 14px 0;
                .main_search_scheme_ul_li {
                  padding: 0 15px;
                  font-size: 18px;
                  line-height: 1;
                }
              }
            }
            .main_search_scheme_input_r {
              height: 48px;
              padding-left: 16px;
              cursor: pointer;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  .vice {
    width: 100%;
    min-width: 1520px;
    background-color: #fff;
    box-shadow: 0 3px 6px 1px rgba(3, 96, 230, 0.06);
    .vice_box {
      width: 1520px;
      min-width: 1520px;
      height: 60px;
      margin: 0 auto;
      justify-content: space-between;
      .vice_left {
        .vice_item {
          border-right: 2px solid #4E4E4E;
          padding-right: 20px;
          margin-right: 20px;
          .vice_num {
            font-size: 14px;
            font-weight: 500;
            color: #4E4E4E;
            > span {
              font-size: 18px;
              color: #1890FF;
              font-weight: bold;
            }
          }
        }
        :last-child {
          border: none;
          padding: 0;
          margin: 0;
        }
      }
      .vice_right {
        .vice_right_vip {
          width: 120px;
          height: 40px;
          > img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item {
    width: 170px;
    border: none;
    margin: 0;
    color: #fff;
    font-size: 18px;
  }
  /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item+.el-tabs__item, .el-tabs--border-card>.el-tabs__header .el-tabs__item:first-child {
    width: 170px;
    margin: 0;
  }
  /deep/ .el-tabs--border-card > .el-tabs__content {
    padding: 0;
  }
  /deep/ .el-tabs--border-card {
    background: none;
    border: none;
    margin: 0;
    box-shadow: none;
  }
  /deep/ .el-tabs--border-card>.el-tabs__header {
    border: none;
    background: none;
  }
  /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
    width: 170px;
    font-weight: bold;
    color: #1890FF;
  }
  /deep/ .el-cascader-panel.is-bordered {
    border: none;
  }
</style>
